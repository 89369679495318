import { ParamMetaData } from "@aex/shared/common-lib";
import {HttpClient, HttpResponse} from "@angular/common/http";
import { Observable } from "rxjs";

const GENERAL_ERROR_TEXT = 'general';

export class ApiBaseService {

	constructor(protected readonly http: HttpClient) { }

	protected getHandlerErrorText(): string{
		return GENERAL_ERROR_TEXT;
	}

	protected get<ResponseObject>(url: string): Observable<ResponseObject> {
		return this.http.get<ResponseObject>(
			url,
			{ params: new ParamMetaData({ handleError: this.getHandlerErrorText()}) },
		);
	}

	protected put<RequestObject, ResponseObject>(url: string, data: RequestObject): Observable<ResponseObject> {
		return this.http.put<ResponseObject>(
			url,
			data,
			{ params: new ParamMetaData({ handleError: this.getHandlerErrorText()}) },
		);
	}

	protected post<RequestObject, ResponseObject>(url: string, data: RequestObject): Observable<ResponseObject> {
		return this.http.post<ResponseObject>(
			url,
			data,
			{ params: new ParamMetaData({ handleError: this.getHandlerErrorText()}) },
		);
	}

	protected postWithHttpResponse<RequestObject, ResponseObject>(url: string, data: RequestObject): Observable<HttpResponse<ResponseObject>> {
		return this.http.post<ResponseObject>(
			url,
			data,
			{
				params: new ParamMetaData({ handleError: this.getHandlerErrorText()}),
				observe: 'response',
			},
		);
	}

	protected blobPost<RequestObject>(url: string, data: RequestObject): Observable<HttpResponse<Blob>> {
		return this.http.post<Blob>(
			url,
			data,
			{
				headers: {
					Accept: 'text/csv',
					'Content-Type': 'application/json',
				},
				responseType: 'blob' as 'json',
				observe: 'response',
			},
		);
	}  

	protected delete<ResponseObject>(url: string): Observable<ResponseObject> {
		return this.http.delete<ResponseObject>(
			url,
			{ params: new ParamMetaData({ handleError: this.getHandlerErrorText()}) },
		);
	}
}
