import {CUSTOMER_ROUTES, ISidebarItem, PORTAL, PROMO_AND_DISCOUNT_ROUTES} from '@aex/shared/common-lib';
import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerComponent, DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { filter } from 'rxjs/operators';
import { CLASSIC_PORTAL_ROUTES } from '../classic-portal/classic-portal.routes';

/* eslint-disable max-len */
const iconObj = {
	homeIcon: {
		name: 'Home',
		content: `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
		<path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
		</svg>
      `,
		viewBox: '0 0 576 512',
	},
	customerIcon: {
		name: 'Customers',
		content: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
		<path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
		</svg>
		`,
		viewBox: '0 0 470 512',
	},
	tasksIcon: {
		name: 'Tasks',
		content: `
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
</svg>`,
		viewBox: '0 0 512 512',
	},
	pMOIcon: {
		name: 'PMO',
		content: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M357.7 8.5c-12.3-11.3-31.2-11.3-43.4 0l-208 192c-9.4 8.6-12.7 22-8.5 34c87.1 25.3 155.6 94.2 180.3 181.6H464c26.5 0 48-21.5 48-48V256h32c13.2 0 25-8.1 29.8-20.3s1.6-26.2-8.1-35.2l-208-192zM288 208c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H304c-8.8 0-16-7.2-16-16V208zM24 256c-13.3 0-24 10.7-24 24s10.7 24 24 24c101.6 0 184 82.4 184 184c0 13.3 10.7 24 24 24s24-10.7 24-24c0-128.1-103.9-232-232-232zm8 256a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM0 376c0 13.3 10.7 24 24 24c48.6 0 88 39.4 88 88c0 13.3 10.7 24 24 24s24-10.7 24-24c0-75.1-60.9-136-136-136c-13.3 0-24 10.7-24 24z"/>
</svg>`,
		viewBox: '0 0 576 512',
	},
	reportingIcon: {
		name: 'Reporting',
		content: `
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/>
</svg>`,
		viewBox: '0 0 512 512',
	},
	networkIcon: {
		name: 'Network',
		content: `
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M62.6 2.3C46.2-4.3 27.6 3.6 20.9 20C7.4 53.4 0 89.9 0 128s7.4 74.6 20.9 108c6.6 16.4 25.3 24.3 41.7 17.7S86.9 228.4 80.3 212C69.8 186.1 64 157.8 64 128s5.8-58.1 16.3-84C86.9 27.6 79 9 62.6 2.3zm450.8 0C497 9 489.1 27.6 495.7 44C506.2 69.9 512 98.2 512 128s-5.8 58.1-16.3 84c-6.6 16.4 1.3 35 17.7 41.7s35-1.3 41.7-17.7c13.5-33.4 20.9-69.9 20.9-108s-7.4-74.6-20.9-108C548.4 3.6 529.8-4.3 513.4 2.3zM340.1 165.2c7.5-10.5 11.9-23.3 11.9-37.2c0-35.3-28.7-64-64-64s-64 28.7-64 64c0 13.9 4.4 26.7 11.9 37.2L98.9 466.8c-7.3 16.1-.2 35.1 15.9 42.4s35.1 .2 42.4-15.9L177.7 448H398.3l20.6 45.2c7.3 16.1 26.3 23.2 42.4 15.9s23.2-26.3 15.9-42.4L340.1 165.2zM369.2 384H206.8l14.5-32H354.7l14.5 32zM288 205.3L325.6 288H250.4L288 205.3zM163.3 73.6c5.3-12.1-.2-26.3-12.4-31.6s-26.3 .2-31.6 12.4C109.5 77 104 101.9 104 128s5.5 51 15.3 73.6c5.3 12.1 19.5 17.7 31.6 12.4s17.7-19.5 12.4-31.6C156 165.8 152 147.4 152 128s4-37.8 11.3-54.4zM456.7 54.4c-5.3-12.1-19.5-17.7-31.6-12.4s-17.7 19.5-12.4 31.6C420 90.2 424 108.6 424 128s-4 37.8-11.3 54.4c-5.3 12.1 .2 26.3 12.4 31.6s26.3-.2 31.6-12.4C466.5 179 472 154.1 472 128s-5.5-51-15.3-73.6z"/>
</svg>`,
		viewBox: '0 0 576 512',
	},
	monitoringIcon: {
		name: 'Monitoring',
		content: `
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M54.2 202.9C123.2 136.7 216.8 96 320 96s196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32S90.3 79.5 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9zM320 256c56.8 0 108.6 21.1 148.2 56c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192s-139.8 27.2-190.5 72c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c39.5-34.9 91.3-56 148.2-56zm64 160a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/>
</svg>`,
		viewBox: '0 0 640 512',
	},
	productsIcon: {
		name: 'Products',
		content: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/>
</svg>`,
		viewBox: '0 0 512 512',
	},
	schedulerIcon: {
		name: 'Scheduler',
		content: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/>
</svg>`,
		viewBox: '0 0 448 512',
	},
	adminIcon: {
		name: 'Admin',
		content: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
<path d="M308.5 135.3c7.1-6.3 9.9-16.2 6.2-25c-2.3-5.3-4.8-10.5-7.6-15.5L304 89.4c-3-5-6.3-9.9-9.8-14.6c-5.7-7.6-15.7-10.1-24.7-7.1l-28.2 9.3c-10.7-8.8-23-16-36.2-20.9L199 27.1c-1.9-9.3-9.1-16.7-18.5-17.8C173.9 8.4 167.2 8 160.4 8h-.7c-6.8 0-13.5 .4-20.1 1.2c-9.4 1.1-16.6 8.6-18.5 17.8L115 56.1c-13.3 5-25.5 12.1-36.2 20.9L50.5 67.8c-9-3-19-.5-24.7 7.1c-3.5 4.7-6.8 9.6-9.9 14.6l-3 5.3c-2.8 5-5.3 10.2-7.6 15.6c-3.7 8.7-.9 18.6 6.2 25l22.2 19.8C32.6 161.9 32 168.9 32 176s.6 14.1 1.7 20.9L11.5 216.7c-7.1 6.3-9.9 16.2-6.2 25c2.3 5.3 4.8 10.5 7.6 15.6l3 5.2c3 5.1 6.3 9.9 9.9 14.6c5.7 7.6 15.7 10.1 24.7 7.1l28.2-9.3c10.7 8.8 23 16 36.2 20.9l6.1 29.1c1.9 9.3 9.1 16.7 18.5 17.8c6.7 .8 13.5 1.2 20.4 1.2s13.7-.4 20.4-1.2c9.4-1.1 16.6-8.6 18.5-17.8l6.1-29.1c13.3-5 25.5-12.1 36.2-20.9l28.2 9.3c9 3 19 .5 24.7-7.1c3.5-4.7 6.8-9.5 9.8-14.6l3.1-5.4c2.8-5 5.3-10.2 7.6-15.5c3.7-8.7 .9-18.6-6.2-25l-22.2-19.8c1.1-6.8 1.7-13.8 1.7-20.9s-.6-14.1-1.7-20.9l22.2-19.8zM112 176a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM504.7 500.5c6.3 7.1 16.2 9.9 25 6.2c5.3-2.3 10.5-4.8 15.5-7.6l5.4-3.1c5-3 9.9-6.3 14.6-9.8c7.6-5.7 10.1-15.7 7.1-24.7l-9.3-28.2c8.8-10.7 16-23 20.9-36.2l29.1-6.1c9.3-1.9 16.7-9.1 17.8-18.5c.8-6.7 1.2-13.5 1.2-20.4s-.4-13.7-1.2-20.4c-1.1-9.4-8.6-16.6-17.8-18.5L583.9 307c-5-13.3-12.1-25.5-20.9-36.2l9.3-28.2c3-9 .5-19-7.1-24.7c-4.7-3.5-9.6-6.8-14.6-9.9l-5.3-3c-5-2.8-10.2-5.3-15.6-7.6c-8.7-3.7-18.6-.9-25 6.2l-19.8 22.2c-6.8-1.1-13.8-1.7-20.9-1.7s-14.1 .6-20.9 1.7l-19.8-22.2c-6.3-7.1-16.2-9.9-25-6.2c-5.3 2.3-10.5 4.8-15.6 7.6l-5.2 3c-5.1 3-9.9 6.3-14.6 9.9c-7.6 5.7-10.1 15.7-7.1 24.7l9.3 28.2c-8.8 10.7-16 23-20.9 36.2L315.1 313c-9.3 1.9-16.7 9.1-17.8 18.5c-.8 6.7-1.2 13.5-1.2 20.4s.4 13.7 1.2 20.4c1.1 9.4 8.6 16.6 17.8 18.5l29.1 6.1c5 13.3 12.1 25.5 20.9 36.2l-9.3 28.2c-3 9-.5 19 7.1 24.7c4.7 3.5 9.5 6.8 14.6 9.8l5.4 3.1c5 2.8 10.2 5.3 15.5 7.6c8.7 3.7 18.6 .9 25-6.2l19.8-22.2c6.8 1.1 13.8 1.7 20.9 1.7s14.1-.6 20.9-1.7l19.8 22.2zM464 304a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
</svg>`,
		viewBox: '0 0 640 512',
	},

	businessIcon: {
		name: 'Business',
		content: `
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M16.3335 4.41667H12.6668V2.58333L10.8335 0.75H7.16683L5.3335 2.58333V4.41667H1.66683C0.658496 4.41667 -0.166504 5.24167 -0.166504 6.25V10.8333C-0.166504 11.5208 0.200163 12.0983 0.750163 12.4192V15.4167C0.750163 16.4342 1.566 17.25 2.5835 17.25H15.4168C16.4343 17.25 17.2502 16.4342 17.2502 15.4167V12.41C17.791 12.0892 18.1668 11.5025 18.1668 10.8333V6.25C18.1668 5.24167 17.3418 4.41667 16.3335 4.41667ZM7.16683 2.58333H10.8335V4.41667H7.16683V2.58333ZM1.66683 6.25H16.3335V10.8333H11.7502V8.08333H6.25016V10.8333H1.66683V6.25ZM9.91683 11.75H8.0835V9.91667H9.91683V11.75ZM15.4168 15.4167H2.5835V12.6667H6.25016V13.5833H11.7502V12.6667H15.4168V15.4167Z" fill="black"/>
  </svg>`,
		viewBox: '0 0 18 18',
	},
};
const MENU_ITEM_ACTIVE_CLASS = 'active';

const SIDEBAR_ITEMS: ISidebarItem[] = [
	{
		text: 'Home',
		svgIcon: iconObj.homeIcon,
		url: PORTAL.dashboardPage.name,
	},
	{
		text: 'Customers (Services)',
		svgIcon: iconObj.customerIcon,
		url: PORTAL.customerPage.name,
	},
	{
		text: 'Customers (Services) - NEW',
		svgIcon: iconObj.customerIcon,
		url: CUSTOMER_ROUTES.view.name,
	},
	{
		text: 'Tasks (Work Orders)',
		svgIcon: iconObj.tasksIcon,
		url: PORTAL.taskPage.name,
	},
	{
		text: 'PMO',
		items: [
			{ text: 'Premise Management', url: CLASSIC_PORTAL_ROUTES.premiseManagement.route.name },
			{ text: 'Premises', url: CLASSIC_PORTAL_ROUTES.predefinedPremises.route.name },
			{ text: 'Client Premises', url: CLASSIC_PORTAL_ROUTES.clientPremises.route.name },
			{ text: 'Areas and Complexes', url: CLASSIC_PORTAL_ROUTES.areasAndComplexes.route.name },
			{ text: 'Addresses', url: CLASSIC_PORTAL_ROUTES.addresses.route.name },
			{ text: 'Locations', url: CLASSIC_PORTAL_ROUTES.locations.route.name },
		],
		svgIcon: iconObj.pMOIcon,
	},
	{
		text: 'Automated Insights',
		svgIcon: iconObj.reportingIcon,
		url: PORTAL.automatedInsights.name,
	},
	{
		text: 'Network',
		items: [
			{ text: 'Devices', url: PORTAL.devices.name },
			{ text: 'Interconnects', url: CLASSIC_PORTAL_ROUTES.interconnects.route.name },
			{ text: 'Manage Interconnects', url: CLASSIC_PORTAL_ROUTES.manageInterconnects.route.name },
			{ text: 'VLANs', url: CLASSIC_PORTAL_ROUTES.vlans.route.name },
		],
		svgIcon: iconObj.networkIcon,
	},
	{
		text: 'Products',
		svgIcon: iconObj.productsIcon,
		url: CLASSIC_PORTAL_ROUTES.products.route.name,
	},
	{
		text: 'Scheduler',
		svgIcon: iconObj.schedulerIcon,
		url: CLASSIC_PORTAL_ROUTES.scheduler.route.name,
	},
	{
		text: 'Admin',
		items: [
			{ text: 'Manage Users', url: CLASSIC_PORTAL_ROUTES.manageUsers.route.name },
			{ text: 'Task Status Allocation', url: CLASSIC_PORTAL_ROUTES.taskStatusAllocation.route.name },
			{ text: 'Billing Schemes', url: CLASSIC_PORTAL_ROUTES.billingSchemes.route.name },
			{ text: 'Provider Billing', url: CLASSIC_PORTAL_ROUTES.providerBilling.route.name },
			{ text: 'Business Rules', url: CLASSIC_PORTAL_ROUTES.businessRules.route.name },
			{ text: 'Promotions And Discounts', url: PROMO_AND_DISCOUNT_ROUTES.promotionsAndDiscounts.name },
		],
		svgIcon: iconObj.adminIcon,
	},
];
/* eslint-enable max-len */


@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
})
export class LayoutComponent {
	@ViewChild('drawer') public drawer!: DrawerComponent;
	public activeRoute: string;
	public items: ISidebarItem[] = [];
	public fno: string;
	public drawerWidth: number = 300;
	public drawerMinWidth: number = 100;
	public server: string;
	public footerText: string = `Integrated Network Management System© Automation Exchange ${new Date().getFullYear()} | All rights reserved.`;
	public selected: string = 'Home';
	public expandedIndices: number[] = [0];
	public readonly hoverDelay: number = 0;

	constructor(private readonly router: Router) {
		this.activeRoute = this.router.url;
		this.items = SIDEBAR_ITEMS;
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.activeRoute = event.url;
			this.setActiveRoute();
		});
	}

	public handleOnSelectMenu(event: MenuSelectEvent): void {
		event.preventDefault();
		if (event.item.url) {
			this.toggleDrawer(false);
			this.router.navigate([event.item.url]).then();
		}
	}

	public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
		return this.expandedIndices.indexOf(item.id) >= 0;
	};

	public toggleDrawer(val: boolean): void {
		this.drawer.expanded = val;
	}

	private setActiveRoute(): void {
		let isActivated = false;
		this.items = this.items.map((item) => {
			if (this.activeRoute.includes(item.url) && !isActivated) {
				isActivated = true;
				return { ...item, cssClass: MENU_ITEM_ACTIVE_CLASS };
			} else {
				const { cssClass: _, ...rest } = item;
				return { ...rest };
			}
		});
	}
}
