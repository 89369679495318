<div class="layout-container" data-testid="layout-container">
	<app-header data-testid="header"></app-header>
	<kendo-drawer-container class="drawer-container">
		<kendo-drawer
			#drawer
			(mouseenter)="toggleDrawer(true)"
			[autoCollapse]="false"
			[expanded]="false"
			[isItemExpanded]="isItemExpanded"
			[miniWidth]="drawerMinWidth"
			[mini]="true"
			[width]="drawerWidth"
			class="drawer"
			mode="push"
		>
			<ng-template kendoDrawerTemplate>
				<kendo-menu [hoverDelay]="hoverDelay" (select)="handleOnSelectMenu($event)" [items]="items" [vertical]="true" class="layout-menu" data-testid="sidebar-menu">
					<ng-template class="bg-black" kendoMenuItemTemplate let-index="index" let-item="item">
						<span [class.hidden]="!drawer?.expanded" [kendoMenuItemLink]="index">
							{{ item.text }}
						</span>
					</ng-template>
				</kendo-menu>
			</ng-template>
		</kendo-drawer>
		<kendo-drawer-content (mouseenter)="toggleDrawer(false)" class="overflow-hidden" data-testid="content" id="inner-content">
			<div class="layout-content-container">
				<ngx-ui-loader [loaderId]="'inner-loader'"></ngx-ui-loader>
				<router-outlet data-testid="content-outlet"></router-outlet>
				<!-- Footer -->
<!--				#todo - do not remove -->
<!--				<div class="footer-container">-->
<!--					&lt;!&ndash;					constant is used here instead of direct content as it will be dynamic&ndash;&gt;-->
<!--					<p class="footer-text">{{ footerText }}</p>-->
<!--				</div>-->
			</div>
		</kendo-drawer-content>
	</kendo-drawer-container>
</div>
<!-- <div kendoDialogContainer></div> -->