<div class="flex flex-row gap-2 items-center" ngModelGroup="promoDateRangeFilterModelGroup">

    <!-- Selected -->
     <div style="width:200px; margin-top: 16px;">
        <kendo-checkbox
          #selectedCheckBox
          [name]="namePrefix + 'selected'"
          [ngModel]="promoDateRangeItem.selected"
          (ngModelChange)="selectedChange($event)"
          name="selected"
				>
        </kendo-checkbox>
        <kendo-label
          class="k-checkbox-label"
          [for]="selectedCheckBox"
          text={{promoDateRangeItem.display_filter_data_type}}>
        </kendo-label>
     </div>


    <!-- Date Range operator -->
    <kendo-formfield style="width:160px">
      <kendo-floatinglabel class="w-full" text="Filter Range">

        <kendo-dropdownlist
          #dateRangeOperator="ngModel"
          [name]="namePrefix + 'dateRangeOperator'"
          [data]="dateRangeOperatorList"
          textField="name"
          valueField="id"
          [valuePrimitive]="true"
          [ngModel]="promoDateRangeItem.date_range_operator_id"
          (ngModelChange)="dateRangeOperatorChanged($event)"
          [disabled]="!promoDateRangeItem.selected"
          required>
        </kendo-dropdownlist>
        <kendo-formerror *ngIf="dateRangeOperator.touched && dateRangeOperator.errors?.['required']">
          Date Range Filter Operator is required.
        </kendo-formerror>

      </kendo-floatinglabel>
    </kendo-formfield>

    <!-- Start Date Filter -->
    <kendo-formfield style="width: 160px;">
      <kendo-floatinglabel class="w-full" [text]="startDateFloatingLabel">

        <kendo-datepicker
          #start_date="ngModel"
          [name]="namePrefix + 'start_date'"
          [(ngModel)]="promoDateRangeItem.date_start"
          [disabled]="!promoDateRangeItem.selected"
          [required]="promoDateRangeItem.selected">
        </kendo-datepicker>
        <kendo-formerror *ngIf="start_date.touched && promoDateRangeItem.selected && start_date.errors?.['required']">
          Date is required.
        </kendo-formerror>

      </kendo-floatinglabel>
    </kendo-formfield>

    <!-- End Date Filter -->
    <div [hidden]="endDateDisabled" style="width: 160px;">

      <kendo-formfield>
        <kendo-floatinglabel class="w-full" text="To">
          <kendo-datepicker
            #end_date="ngModel"
            [name]="namePrefix + 'end_date'"
            [(ngModel)]="promoDateRangeItem.date_end"
            [disabled]="!promoDateRangeItem.selected"
            [required]="!endDateDisabled">
          </kendo-datepicker>
          <kendo-formerror *ngIf="isEndDateRequiredCheck">
            Date is required.
          </kendo-formerror>
          <kendo-formerror *ngIf="isEndDateOnOrAfterCheck">
            Date must be after or on the From Date.
          </kendo-formerror>
        </kendo-floatinglabel>
      </kendo-formfield>

    </div>

</div>
<!-- #todo:portal2 - check whether we need this <br/> -->
<br/>
