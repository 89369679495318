import { WithDestroy, NavigationService } from '@aex/ngx-toolbox';
import { OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowPostMessageService } from '../../../services/window-post-message.service';
import { ClassicPortalRouteMappingService } from '../services/classic-portal-route-mapping.service';

@Component({
  selector: 'app-classic-portal-container',
  templateUrl: './classic-portal-container.component.html',
  styleUrls: [],
})
export class ClassicPortalContainerComponent extends WithDestroy() implements OnInit, OnDestroy, AfterViewInit {

	public pageTitle: string;
	public classicPortalIframeUrl: string;
	public isIframeReady = false;
	public isCookieAuthenticated = false;

	@ViewChild('portalIframe') public portalIframeRef: ElementRef<HTMLIFrameElement>;

	constructor(
		private readonly classicPortalRouteMappingService: ClassicPortalRouteMappingService,
		private readonly route: ActivatedRoute,
		private readonly windowPostMessageService: WindowPostMessageService,
		private readonly navigationService: NavigationService,
	) {
		super();
		this.navigationService.startLoading(this.windowPostMessageService.loader);
		this.pageTitle = this.route.snapshot.data['title'];
		this.windowPostMessageService.registerNavigationHandler();
		this.windowPostMessageService.registerLoginHandler();
		this.windowPostMessageService.registerCookieAuthHandler();

		this.noZombie(this.windowPostMessageService.isIframeReadyStream).subscribe(
			(isReady: boolean) => {
				this.isIframeReady = isReady;
			},
		);
		this.noZombie(this.windowPostMessageService.isCookieAuthenticatedStream).subscribe(
			(isReady: boolean) => {
				this.isCookieAuthenticated = isReady;
			},
		);
	}

	public ngOnInit(): void {
		this.classicPortalIframeUrl = this.classicPortalRouteMappingService.getCurrentRouteUrl();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.windowPostMessageService.removeLoginHandler();
		this.windowPostMessageService.removeCookieAuthHandler();
		this.windowPostMessageService.removeNavigationHandler();
		this.windowPostMessageService.isIframeReady = false;
		this.windowPostMessageService.isCookieAuthenticated = false;
	}

	public ngAfterViewInit(): void {
		this.windowPostMessageService.portalIframeRef = this.portalIframeRef;
	}
}
