import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, delay, map } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";

import { IPagedResponse } from "@aex/ngx-toolbox";

import { instanceToInstance, plainToInstance } from "class-transformer";

import {TableCacheService} from "./table-cache.service"; // Used for cacheing table data
import {PricingServiceApiService, SearchService} from "@aex/shared/apis";

import { promoStatusAsText, TPromoStatus} from "../helpers/promo-discount-helper";

import {
  AreaSearchRequestDto,
  BillingRuleRequestDto,
  BulkCustomerResponseDto,
  IPromoAppliedBy,
  IPromoBillingFrequency,
  IPromoBillingRule,
  IPromoCategory,
  IPromoCodeUsage,
  IPromoPeriodType,
  IPromoPriceReduction,
  IPromoReductionType,
  IPromoServiceStatus,
  ProductSearchRequestDto,
  PromoAreaTreeItem,
  PromoCategoryItem,
  PromoDiscountBulkCustomerRequestDto,
  PromoDiscountDataExportRequestDto,
  PromoDiscountDataItem,
  PromoDiscountDeleteRequestDto,
  PromoDiscountItem,
  PromoDiscountSearchRequestDto,
  PromoDiscountStatusItem,
  PromoPriceReductionItem,
  PromoProductItem as IPromoProduct,
} from "../interfaces/promotion-discounts";

import {
  IColumnDto,
  IFullService,
  IPromoCustomerService,
  ISearchPagedResponse,
  IServiceSearchParams,
} from "../interfaces/search-service";
import { HttpResponse } from "@angular/common/http";
import { generateRandomString } from "../helpers/kendo-helper";
import { getAreaStatusClass, getServiceStatusClass } from "../helpers/search-service-helper";

export const SERVICE_DELAY = 10;
export const TABLE_PROMO_PRICE_REDUCTION = 'PromoPriceReduction';
export const TABLE_PROMO_BILLING_FREQUENCY = 'PromoBillingFrequency';
export const TABLE_PROMO_CATEGORY = 'PromoCategory';
export const TABLE_PROMO_REDUCTION_TYPE = 'PromoReductionType';
export const TABLE_PROMO_PERIOD_TYPE = 'PromoPeriodType';
export const TABLE_PROMO_CODE_USAGE = 'PromoCodeUsage';
export const TABLE_PROMO_APPLIED_BY = 'PromoAppliedBy';
export const TABLE_PROMO_AREA = 'PromoArea';
export const TABLE_PROMO_PRODUCT = 'PromoProduct';
export const TABLE_PROMO_BILLING_RULE = 'PromoBillingRule';
export const TABLE_PROMO_SERVICE_STATUS = 'PromoServiceStatus';
export const TABLE_PROMO_BILLING_STATUS = 'PromoBillingStatus';
export const TABLE_PROMOTION_DISCOUNT = 'PromotionDiscount';
export const TABLE_PROMOTION_DISCOUNT_STATUS = 'PromotionDiscountStatus';

export const SEARCH_SERVICE_COLUMN = 'SearchServiceColumn';

export const CUSTOMER_SERVICE_COLUMNS : string[]= [
  'Service: Id',
  'Customer: Full Name',
  'Customer: Email',
  'Premise: Full Address',
  'Area: Name',
  'Area: Area Status',
  'Service: Status',
  'Isp Product: Name',
];

@Injectable({
	providedIn: 'root',
})
export class PricingServiceService {

  private readonly USE_MOCK = false;

  constructor(
    private readonly toastr: ToastrService,
    private readonly tableCacheService: TableCacheService,
    private readonly pricingServiceApiService: PricingServiceApiService,
    private readonly searchService: SearchService,
  ) {
    if (!this.tableCacheService.existTable(TABLE_PROMO_PERIOD_TYPE)) {
      // tableCacheService has not been initialized -- Seed the Cache
      if (this.USE_MOCK)
        this.getMockPromoPriceReductionList();
      if (this.USE_MOCK)
        this.getMockPromoBillingFrequencyList();
      if (this.USE_MOCK)
        this.getMockPromoCategoryList();
      if (this.USE_MOCK)
        this.getMockPromoReductionTypeList();

      this.getMockPromoPeriodTypeList();

      if (this.USE_MOCK)
        this.getMockPromoCodeUsageList();

      this.getMockPromoAppliedByList();         // Maybe deprecated due to Promo Code Form no longer used

      if (this.USE_MOCK)
        this.getMockPromoAreaList();              //
      if (this.USE_MOCK)
        this.getMockPromoProductList();
	    if (this.USE_MOCK)
				this.getMockPromoBillingRuleList();
      // if (this.USE_MOCK) - always fetch the mocked servicestatusList for now
      this.getMockPromoServiceStatusList();

      this.initPromoDiscountData();

      this.seedPromoDiscountStatusData();
    }
  }

  //#region --- Promo Discount Lookup/Dropdown Data ---

  // Get Promo Price Reductions List: Discount/Promotion
  public getPromoPriceReductionList(): Observable<IPromoPriceReduction[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoPriceReduction>(TABLE_PROMO_PRICE_REDUCTION);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const records = this.FetchCachedTableData<IPromoPriceReduction>(TABLE_PROMO_PRICE_REDUCTION);
    if (records.length > 0)
      return of(records).pipe(delay(SERVICE_DELAY));

    return this.pricingServiceApiService.getPromoPriceReductionList().pipe(
      map((promoPriceReduction: IPromoPriceReduction[]) => {
        // Cache the data
        this.CacheTableData(TABLE_PROMO_PRICE_REDUCTION, promoPriceReduction);
        return promoPriceReduction;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo Price Reductions: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  // Get Promo Billing Frequency list / Price Type List
  public getPromoBillingFrequencyList(): Observable<IPromoBillingFrequency[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoBillingFrequency>(TABLE_PROMO_BILLING_FREQUENCY);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const records = this.FetchCachedTableData<IPromoBillingFrequency>(TABLE_PROMO_BILLING_FREQUENCY);
    if (records.length > 0)
      return of(records).pipe(delay(SERVICE_DELAY));

    return this.pricingServiceApiService.getPromoBillingFrequencyList().pipe(
      map((promoBillingFrequency: IPromoBillingFrequency[]) => {
        // Cache the data
        this.CacheTableData(TABLE_PROMO_BILLING_FREQUENCY, promoBillingFrequency);
        return promoBillingFrequency;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo Billing Frequencies: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  // Get Promo Category list
  public getPromoCategoryList(): Observable<IPromoCategory[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoCategory>(TABLE_PROMO_CATEGORY);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const records = this.FetchCachedTableData<IPromoCategory>(TABLE_PROMO_CATEGORY);
    if (records.length > 0) {
      records.sort((a, b) => a.category_name.localeCompare(b.category_name));
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    return this.pricingServiceApiService.getPromoCategoryList().pipe(
      map((promoCategoryList: IPromoCategory[]) => {
        // Cache the data
        this.CacheTableData(TABLE_PROMO_CATEGORY, promoCategoryList);
        // Sort the list by category name
        promoCategoryList.sort((a, b) => a.category_name.localeCompare(b.category_name));

        return promoCategoryList;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo categories: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  // Get Promo Reduction Type List
  public getPromoReductionTypeList(): Observable<IPromoReductionType[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoReductionType>(TABLE_PROMO_REDUCTION_TYPE);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const records = this.FetchCachedTableData<IPromoReductionType>(TABLE_PROMO_REDUCTION_TYPE);
    if (records.length > 0) {
      records.sort((a, b) => a.description.localeCompare(b.description));
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    return this.pricingServiceApiService.getPromoReductionTypeList().pipe(
      map((promoReductionTypeList: IPromoReductionType[]) => {
        // Cache the data
        this.CacheTableData(TABLE_PROMO_REDUCTION_TYPE, promoReductionTypeList);
        promoReductionTypeList.sort((a, b) => a.description.localeCompare(b.description));
        return promoReductionTypeList;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo Reduction Types: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  public getPromoPeriodTypeList(): Observable<IPromoPeriodType[]> {
      const records = this.tableCacheService.getAll<IPromoPeriodType>(TABLE_PROMO_PERIOD_TYPE);
      return of(records).pipe(delay(SERVICE_DELAY));
    // Dont know id Pricing Service has this API - It does not KR
    // For now use the mock? As always monthly?
    // return this.pricingServiceApiService.getPromoPeriodTypeList().pipe(
    //   map((promoPeriodTypeList: IPromoPeriodType[]) =>
    //     promoPeriodTypeList.sort((a, b) => a.name.localeCompare(b.name)),
    //   ),
    //   catchError((error) => {
    //     this.toastr.error(`Error fetching promo Period Types: ${error.message || error}`);
    //     return of([]); // Return an empty array as a fallback
    //   }),
    // );
  }

  public getPromoCodeUsageList(): Observable<IPromoCodeUsage[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoCodeUsage>(TABLE_PROMO_CODE_USAGE);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const records = this.FetchCachedTableData<IPromoCodeUsage>(TABLE_PROMO_CODE_USAGE);
    if (records.length > 0) {
      records.sort((a, b) => a.name.localeCompare(b.name));
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    return this.pricingServiceApiService.getPromoCodeUsageList().pipe(
      map((promoCodeUsageList: IPromoCodeUsage[]) => {
        // Cache the data
        this.CacheTableData(TABLE_PROMO_CODE_USAGE, promoCodeUsageList);
        promoCodeUsageList.sort((a, b) => a.name.localeCompare(b.name));
        return promoCodeUsageList;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo Code Usages: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  public getPromoAppliedByList(): Observable<IPromoAppliedBy[]> {
    // if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoAppliedBy>(TABLE_PROMO_APPLIED_BY);
      return of(records).pipe(delay(SERVICE_DELAY));
    // }

    // return this.pricingServiceApiService.getPromoAppliedByList().pipe(
    //   map((promoAppliedByList: IPromoAppliedBy[]) =>
    //     promoAppliedByList.sort((a, b) => a.name.localeCompare(b.name)),
    //   ),
    //   catchError((error) => {
    //     this.toastr.error(`Error fetching promo Applied By list: ${error.message || error}`);
    //     return of([]); // Return an empty array as a fallback
    //   }),
    // );
  }

  public getPromoAreaList(areaSearchRequestDto: AreaSearchRequestDto): Observable<PromoAreaTreeItem[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<PromoAreaTreeItem>(TABLE_PROMO_AREA);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const canCache = (areaSearchRequestDto.product_ids.length === 0) && (areaSearchRequestDto.billing_rule_ids.length === 0);
    if (canCache) {
      // Check if the table has been cached
      const records = this.FetchCachedTableData<PromoAreaTreeItem>(TABLE_PROMO_AREA);
      if (records.length > 0) {
        const sortedData = this.sortTree(records);
        return of(sortedData).pipe(delay(SERVICE_DELAY));
      }
    }

    return this.pricingServiceApiService.getPromoAreaList(areaSearchRequestDto).pipe(
      map((promoAreaList: PromoAreaTreeItem[]) => {
        // Cache the data if applicable
        if (canCache)
          this.CacheTableData(TABLE_PROMO_AREA, promoAreaList);

        // Sort the tree
        // Return the processed list
        return this.sortTree(promoAreaList);
      }),
      catchError((error) => {
        // Handle errors and provide fallback
        this.toastr.error(`Error fetching promo Area list: ${error.message || error}`);
        return of([]); // Return an empty array as fallback
      }),
    );
  }

  public getPromoProductList(productSearchRequestDto: ProductSearchRequestDto): Observable<IPromoProduct[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoProduct>(TABLE_PROMO_PRODUCT);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const canCache = (productSearchRequestDto.area_ids.length === 0) && (productSearchRequestDto.billing_rule_ids.length === 0);
    if (canCache) {
      // Check if the table has been cached
      const records = this.FetchCachedTableData<IPromoProduct>(TABLE_PROMO_PRODUCT);
      if (records.length > 0) {
        records.sort((a, b) => a.name.localeCompare(b.name));
        return of(records).pipe(delay(SERVICE_DELAY));
      }
    }

    return this.pricingServiceApiService.getPromoProductList(productSearchRequestDto).pipe(
      map((promoProductList: IPromoProduct[]) => {
        // If the data can be cached, cache it
        if (canCache)
          this.CacheTableData(TABLE_PROMO_PRODUCT, promoProductList);

        promoProductList.sort((a, b) => a.name.localeCompare(b.name));
        return promoProductList;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo Product list: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  // Get Promo Billing Rule List
  public getPromoBillingRuleList(billingRuleRequestDto: BillingRuleRequestDto): Observable<IPromoBillingRule[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoBillingRule>(TABLE_PROMO_BILLING_RULE);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const canCache = (billingRuleRequestDto.area_ids.length === 0) && (billingRuleRequestDto.product_ids.length === 0);
    if (canCache) {
      // Check if the table has been cached
      const records = this.FetchCachedTableData<IPromoBillingRule>(TABLE_PROMO_BILLING_RULE);
      if (records.length > 0) {
        records.sort((a, b) => a.name.localeCompare(b.name));
        return of(records).pipe(delay(SERVICE_DELAY));
      }
    }

    return this.pricingServiceApiService.getPromoBillingRuleList(billingRuleRequestDto).pipe(
      map((promoBillingRuleList: IPromoBillingRule[]) => {
        // Cache the data if applicable
        if (canCache)
          this.CacheTableData(TABLE_PROMO_BILLING_RULE, promoBillingRuleList);

        // Sort the data
        promoBillingRuleList.sort((a, b) => a.name.localeCompare(b.name));

        return promoBillingRuleList;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo Billing Rule List: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  // Get Promo Service Status List
  public getPromoServiceStatusList(): Observable<IPromoServiceStatus[]> {
    if (this.USE_MOCK) {
      const records = this.tableCacheService.getAll<IPromoServiceStatus>(TABLE_PROMO_SERVICE_STATUS);
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    // Check if the table has been cached
    const records = this.FetchCachedTableData<IPromoServiceStatus>(TABLE_PROMO_SERVICE_STATUS);
    if (records.length > 0) {
      records.sort((a, b) => a.name.localeCompare(b.name));
      return of(records).pipe(delay(SERVICE_DELAY));
    }

    return this.pricingServiceApiService.getPromoServiceStatusList().pipe(
      map((promoServiceStatusList: IPromoServiceStatus[]) => {
        // Cache the data
        this.CacheTableData(TABLE_PROMO_SERVICE_STATUS, promoServiceStatusList);
        promoServiceStatusList.sort((a, b) => a.name.localeCompare(b.name));
        return promoServiceStatusList;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching promo Service Status List: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  // Get Promo Billing Status List
  // public getPromoBillingStatusList(): Observable<IPromoBillingStatus[]> {
  //     const records = this.tableCacheService.getAll<IPromoBillingStatus>(TABLE_PROMO_BILLING_STATUS);
  //     return of(records).pipe(delay(SERVICE_DELAY));
  //
  //   // return this.pricingServiceApiService.getPromoBillingStatusList().pipe(
  //   //   map((promoBillingStatusList: IPromoServiceStatus[]) =>
  //   //     promoBillingStatusList.sort((a, b) => a.name.localeCompare(b.name)),
  //   //   ),
  //   //   catchError((error) => {
  //   //     this.toastr.error(`Error fetching promo Billing Status List: ${error.message || error}`);
  //   //     return of([]); // Return an empty array as a fallback
  //   //   }),
  //   // );
  // }

  public getPromoDiscountStatusList(): PromoDiscountStatusItem[] {
    const records = this.tableCacheService.getAll<PromoDiscountStatusItem>(TABLE_PROMOTION_DISCOUNT_STATUS);
    return [...records];
  }

  private sortTree(treeList: PromoAreaTreeItem[]): PromoAreaTreeItem[] {
    return treeList
      .map(node => ({
        ...node,
        children: node.children?.length ? this.sortTree(node.children) : node.children, // Only sort if children exist
      }))
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort after processing children
  }

  private CacheTableData<T extends { id: number|string }>(table: string, data: T[]): void {
    data.map((record : T) => this.tableCacheService.set(table, record.id, record));
  }

  private FetchCachedTableData<T>(table: string): T[] {
    return this.tableCacheService.existTable(table) ? this.tableCacheService.getAll<T>(table) : [];
  }

  //#endregion --- Promo Discount Lookup/Dropdown Data ---

  //#region --- Promo Discount Data ---

  public getPromotionDiscountData(searchRequest: PromoDiscountSearchRequestDto): Observable<IPagedResponse<PromoDiscountDataItem>> {
    if (this.USE_MOCK)
      return this.getMockPromotionDiscountData(searchRequest);

    return this.pricingServiceApiService.getPromotionDiscountData(searchRequest).pipe(
      map((response: IPagedResponse<PromoDiscountDataItem>) => {
        // Transform each item to add status and promo_status_class
        const updatedItems = response.items.map(
          (promoDiscountDataItem: PromoDiscountDataItem) => ({
            ...promoDiscountDataItem,
            start_date_value: new Date(promoDiscountDataItem.start_date),
            end_date_value: (promoDiscountDataItem.end_date) ? new Date(promoDiscountDataItem.end_date) : null,
            published_date: (promoDiscountDataItem.published_date) ? new Date(promoDiscountDataItem.published_date) : null,
            promo_status_class: this.getPromoStatusClass(promoDiscountDataItem.status),
          }),
        );

        // Sort the updated items by name
        const sortedItems = updatedItems.sort((a, b) => a.name.localeCompare(b.name));

        // Return the updated response with sorted items
        return {
          ...response,
          items: sortedItems,
        };
      }),
      catchError(() => {
        this.toastr.error(`Error fetching promo discounts. Please try again later.`);
        return of({items: [], page: searchRequest.page_number, count: 0, total: 0}); // Return an empty array as a fallback
      }),
    );

  }

  private getPromoStatusClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'status-active';
      case 'in-active':
        return 'status-future-dated';
      case 'draft':
        return 'status-draft';
      case 'deleted':
        return 'status-deleted';
      default:
        return 'status-default';
    }
  }

  public deletePromotions(deleteRequest: PromoDiscountDeleteRequestDto): Observable<boolean> {
    if (this.USE_MOCK) {
      deleteRequest.promo_discount_ids.forEach((promoId: string) => {
        const recordExists = this.tableCacheService.has(TABLE_PROMOTION_DISCOUNT, promoId);
        if (recordExists) {
          const promoDiscountItem = this.tableCacheService.get<PromoDiscountItem>(TABLE_PROMOTION_DISCOUNT, promoId);
          promoDiscountItem.promo_discount_status_id = TPromoStatus.Deleted;
          this.tableCacheService.set(TABLE_PROMOTION_DISCOUNT, promoId, promoDiscountItem);
        }
      });
      return of(true);
    }

    return this.pricingServiceApiService.deletePromotions(deleteRequest).pipe(
      map(() => true),
      catchError((error) => {
        this.toastr.error(`Error deleting promo discounts: ${error.error.detail || error}`);
        return of(false); // Return an empty array as a fallback
      }),
    );
  }

  public getPromotionDiscountById(promoId: string): Observable<PromoDiscountItem> {
    if (this.USE_MOCK) {
      const response = this.tableCacheService.get<PromoDiscountItem>(TABLE_PROMOTION_DISCOUNT, promoId);
      if ((!response.promo_discount_detail_item.promo_is_perpetual) &&
          (!response.promo_discount_detail_item.promo_end_date_value))
        response.promo_discount_detail_item.promo_end_date_value = new Date();
      return of(response).pipe(delay(SERVICE_DELAY));
    }
    return this.pricingServiceApiService.getPromotionDiscountById(promoId).pipe(
      map((response: PromoDiscountItem) => {
        // Convert data to class since the class has methods
        return plainToInstance(PromoDiscountItem, response);
      }),
      catchError((error) => {
        this.toastr.error(`getPromotionDiscountById error: ${error.error.detail || error}`);
        return of(null); // Return an empty array as a fallback
      }),
    );
  }

  public createPromotionDiscount(promoDiscountItem: PromoDiscountItem): Observable<PromoDiscountItem> {
    if (this.USE_MOCK) {
      const key = generateRandomString(36);
      promoDiscountItem.promo_discount_id = key;
      const clone = instanceToInstance(promoDiscountItem);
      this.tableCacheService.set(TABLE_PROMOTION_DISCOUNT, key, clone);
      return of(clone).pipe(delay(SERVICE_DELAY));
    }
    return this.pricingServiceApiService.createPromotionDiscount(promoDiscountItem).pipe(
      map((response: PromoDiscountItem) =>
        // Convert data to class since the class has methods
        plainToInstance(PromoDiscountItem, response),
      ),
      catchError((error) => {
        this.toastr.error(`createPromotionDiscount error: ${error.error || error}`);
        throw new Error(`Error creating promo discount`);
      }),
    );
  }

  public updatePromotionDiscount(promoDiscountItem: PromoDiscountItem): Observable<PromoDiscountItem> {
    if (this.USE_MOCK) {
      const key = promoDiscountItem.promo_discount_id;
      const clone = instanceToInstance(promoDiscountItem);
      this.tableCacheService.set(TABLE_PROMOTION_DISCOUNT, key, clone);
      return of(clone).pipe(delay(SERVICE_DELAY));
    }

    return this.pricingServiceApiService.updatePromotionDiscount(promoDiscountItem).pipe(
      map((response: PromoDiscountItem) =>
        // Convert data to class since the class has methods
        plainToInstance(PromoDiscountItem, response),
      ),
      catchError((error) => {
        this.toastr.error(`updatePromotionDiscount error: ${error.error || error}`);
        throw new Error(`Error updating promo discounts`);
      }),
    );
  }

  public bulkAddCustomerPromoDiscount(promoId: string, updateRequestDto: PromoDiscountBulkCustomerRequestDto): Observable<BulkCustomerResponseDto> {
    if (this.USE_MOCK) {
      const result = new BulkCustomerResponseDto();
      result.promo_id = promoId;
      result.services_updated = [...updateRequestDto.entity_service_ids];
      result.services_deleted = [];
      return of(result).pipe(delay(SERVICE_DELAY));
    }
    return this.pricingServiceApiService.bulkAddCustomerPromoDiscount(promoId, updateRequestDto).pipe(
      map((response: BulkCustomerResponseDto) =>
        // Convert data to class since the class has methods
        plainToInstance(BulkCustomerResponseDto, response),
      ),
      catchError((error) => {
        this.toastr.error(`bulkAddCustomerPromoDiscount error: ${error.message || error}`);
        throw new Error(`Error bulkAddCustomerPromoDiscount`);
      }),
    );
  }

  public bulkDeleteCustomerPromoDiscount(promoId: string, updateRequestDto: PromoDiscountBulkCustomerRequestDto): Observable<BulkCustomerResponseDto> {
    if (this.USE_MOCK) {
      const result = new BulkCustomerResponseDto();
      result.promo_id = promoId;
      result.services_updated = [...updateRequestDto.entity_service_ids];
      result.services_deleted = [];
      return of(result).pipe(delay(SERVICE_DELAY));
    }
    return this.pricingServiceApiService.bulkDeleteCustomerPromoDiscount(promoId, updateRequestDto).pipe(
      map((response: BulkCustomerResponseDto) =>
        // Convert data to class since the class has methods
        plainToInstance(BulkCustomerResponseDto, response),
      ),
      catchError((error) => {
        this.toastr.error(`bulkDeleteCustomerPromoDiscount error: ${error.message || error}`);
        throw new Error(`Error bulkDeleteCustomerPromoDiscount`);
      }),
    );
  }

  public exportPromotionDiscountData(promoDiscountDataExportRequestDto: PromoDiscountDataExportRequestDto): Observable<HttpResponse<Blob>> {
    if (this.USE_MOCK) {
      // Mock the export
      const dummyBlob = new Blob(['Mocking Dummy promo export data'], { type: 'text/csv' });
      const dummyResponse = new HttpResponse({
        body: dummyBlob,
        status: 200,
      });
      return of(dummyResponse).pipe(delay(SERVICE_DELAY));
    }
    // Call the API
    return this.pricingServiceApiService.exportPromotionDiscountData(promoDiscountDataExportRequestDto);
  }

  public getPromotionDiscountReportById(promoId: string): Observable<PromoDiscountDataItem> {
    if (this.USE_MOCK) {
      const response = this.tableCacheService.get<PromoDiscountItem>(TABLE_PROMOTION_DISCOUNT, promoId);
      const result = this.transformPromoDiscountItemToPromoDiscountDataItem(response);
      return of(result).pipe(delay(SERVICE_DELAY));
    }

    return this.pricingServiceApiService.getPromotionDiscountReportById(promoId).pipe(
      map((response: PromoDiscountDataItem) => {
        return response;
      }),
      catchError((error) => {
        this.toastr.error(`getPromotionDiscountByReportId error: ${error.message || error}`);
        return of(new PromoDiscountDataItem()); // Return an empty array as a fallback
      }),
    );
  }

  //#endregion --- Promo Discount Data ---

  //#region --- Customer Data ---

  // Get the ColumnDto for the Customer Service Grid
  public getGridServiceColumns(): Observable<IColumnDto[]> {
    const requiredColumns: IColumnDto[] = [];
    return this.searchService.getServiceColumns().pipe(
      map((response) => {
        const columns: IColumnDto[] = response.body;
        // Iterate over the CUSTOMER_SERVICE_COLUMNS and extract the corresponding grid column
        CUSTOMER_SERVICE_COLUMNS.forEach((column: string) => {
          const columnDto: IColumnDto = columns.find((columnDto) => columnDto.heading === column);
          if (columnDto)
            requiredColumns.push(columnDto);
        });
        return requiredColumns;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching customer columns: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  public getCustomerServices(request: Partial<IServiceSearchParams>): Observable<ISearchPagedResponse> {
    return this.searchService.getServices(request).pipe(
      map((response) => {
        const responseData: ISearchPagedResponse = response.body;
        const promoCustomerServiceData: IPromoCustomerService[] = this.transformToPromoCustomerServiceDto(responseData.items);
        const result: ISearchPagedResponse = {
          items: promoCustomerServiceData,
          page: responseData.page,
          count: responseData.count,
          total: responseData.total,
          sort_field: responseData.sort_field,
          sort_order: responseData.sort_order,
        };
        return result;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching customer services: ${error.message || error}`);
        return of(null);
      }),
    );
  }

  //#endregion --- Customer Data ---

  //#region --- Data Transformation ---

  private transformPromoDiscountDataItemToPromoDiscount(promoDiscountDataItem: PromoDiscountDataItem): PromoDiscountItem {
    const promoDiscountItem = new PromoDiscountItem();
    promoDiscountItem.promo_discount_id = promoDiscountDataItem.id;
    promoDiscountDataItem.collector_id = '1';
    promoDiscountItem.promo_discount_detail_item.promo_price_reduction_id = promoDiscountDataItem.promo_discount_type_id;
    promoDiscountItem.promo_discount_detail_item.promo_category_id = promoDiscountDataItem.promo_category_id;
    promoDiscountDataItem.eligibility_filter_id = null;
    promoDiscountItem.promo_discount_detail_item.name = promoDiscountDataItem.name;
    promoDiscountItem.promo_discount_detail_item.description = promoDiscountDataItem.description;
    promoDiscountItem.promo_discount_detail_item.promo_line_item_message = promoDiscountDataItem.line_item_message;
    promoDiscountItem.promo_discount_status_id = promoDiscountDataItem.promo_discount_status_id;
    promoDiscountItem.published_date = promoDiscountDataItem.published_date;
    promoDiscountItem.promo_discount_detail_item.auto_enlist_customers = promoDiscountDataItem.auto_enlist;
    promoDiscountItem.promo_discount_detail_item.promo_code = promoDiscountDataItem.promo_code;
    promoDiscountItem.promo_discount_detail_item.gl_code = promoDiscountDataItem.gl_code;
    promoDiscountDataItem.goal_or_marketing_message = null;
    promoDiscountItem.promo_discount_detail_item.promo_start_date = promoDiscountDataItem.start_date;
    promoDiscountItem.promo_discount_detail_item.promo_end_date = promoDiscountDataItem.end_date;
    promoDiscountDataItem.service_usage_count = 1;
    promoDiscountItem.promo_discount_detail_item.promo_billing_frequency_id = promoDiscountDataItem.price_type_id;
    promoDiscountItem.promo_discount_detail_item.waive_activation_pro_rata = promoDiscountDataItem.waive_activation_pro_rata;
    promoDiscountItem.promo_discount_detail_item.promo_reduction_type_id = promoDiscountDataItem.adjustment_type_id;
    promoDiscountItem.promo_discount_detail_item.promo_reduction_amount = promoDiscountDataItem.adjustment_value;
    promoDiscountItem.published_date = promoDiscountDataItem.published_date;
    promoDiscountItem.promo_discount_detail_item.promo_period_type_id = 1;
    promoDiscountItem.promo_discount_detail_item.promo_period_length = promoDiscountDataItem.period_length;
    promoDiscountItem.promo_discount_detail_item.category_query_type_id = promoDiscountDataItem.category_query_type_id;

    return promoDiscountItem;
  }

  private transformPromoDiscountItemToPromoDiscountDataItem(promoDiscountItem: PromoDiscountItem): PromoDiscountDataItem {
    const promotionDiscountData = new PromoDiscountDataItem({
      id: promoDiscountItem.promo_discount_id,
      collector_id: '1',
      promo_discount_type_id: promoDiscountItem.promo_discount_detail_item.promo_price_reduction_id,
      promo_category_id: promoDiscountItem.promo_discount_detail_item.promo_category_id,
      eligibility_filter_id: null,
      name: promoDiscountItem.promo_discount_detail_item.name,
      description: promoDiscountItem.promo_discount_detail_item.description,
      line_item_message: promoDiscountItem.promo_discount_detail_item.promo_line_item_message,
      promo_discount_status_id: promoDiscountItem.promo_discount_status_id,
      published_date: promoDiscountItem.published_date,
      auto_enlist: promoDiscountItem.promo_discount_detail_item.auto_enlist_customers,
      promo_code: promoDiscountItem.promo_discount_detail_item.promo_code,
      gl_code: promoDiscountItem.promo_discount_detail_item.gl_code,
      goal_or_marketing_message: null,
      start_date: promoDiscountItem.promo_discount_detail_item.promo_start_date,
      end_date: promoDiscountItem.promo_discount_detail_item.promo_end_date,
      service_usage_count: 0,
      price_type_id: promoDiscountItem.promo_discount_detail_item.promo_billing_frequency_id,
      waive_activation_pro_rata: promoDiscountItem.promo_discount_detail_item.waive_activation_pro_rata,
      adjustment_type_id: promoDiscountItem.promo_discount_detail_item.promo_reduction_type_id,
      adjustment_value: promoDiscountItem.promo_discount_detail_item.promo_reduction_amount,
      period_length: promoDiscountItem.promo_discount_detail_item.promo_period_length,
      category_query_type_id: promoDiscountItem.promo_discount_detail_item.category_query_type_id,

      collector_name: 'Collector Name 1',
      status: promoStatusAsText(promoDiscountItem.promo_discount_status_id),
      promo_discount_type_name: this.tableCacheService.get<PromoPriceReductionItem>(
        TABLE_PROMO_PRICE_REDUCTION,
        promoDiscountItem.promo_discount_detail_item.promo_price_reduction_id,
      ).code,
      price_type_name: this.tableCacheService.get<IPromoPeriodType>(
        TABLE_PROMO_PERIOD_TYPE,
        promoDiscountItem.promo_discount_detail_item.promo_period_type_id,
      ).name,
      adjustment_type_name: this.tableCacheService.get<IPromoReductionType>(
        TABLE_PROMO_REDUCTION_TYPE,
        promoDiscountItem.promo_discount_detail_item.promo_reduction_type_id,
      ).description,
      promo_status_class: this.getPromoStatusClass(promoStatusAsText(promoDiscountItem.promo_discount_status_id)),
    });
    const recordExist = this.tableCacheService.has(
      TABLE_PROMO_CATEGORY,
      promoDiscountItem.promo_discount_detail_item.promo_category_id,
    );
    if (recordExist)
      promotionDiscountData.promo_category_name = this.tableCacheService.get<PromoCategoryItem>(
        TABLE_PROMO_CATEGORY,
        promoDiscountItem.promo_discount_detail_item.promo_category_id,
      ).category_name;

    return promotionDiscountData;
  }

  private transformToPromoCustomerServiceDto(fullServices: IFullService[]): IPromoCustomerService[] {
    return fullServices.map((fullService: IFullService) => ({
      id: fullService.service.id,
      customer: {
        full_name: fullService.customer.full_name,
        email: fullService.customer.email,
      },
      area: {
        name: fullService.area.name,
        area_status: fullService.area.area_status,
      },
      premise: {
        full_address: fullService.premise.full_address,
      },
      service: {
        id: fullService.service.id,
        status: fullService.service.status,
      },
      isp_product: {
        name: fullService.isp_product.name,
      },
      area_status_class: getAreaStatusClass(fullService.area.area_status),
      service_status_class: getServiceStatusClass(fullService.service.status),
    }));
  }

  //#endregion --- Data Transformation ---

  //#region --- Seed Data ---

  public seedPromoDiscountStatusData(): void {
    const promoDiscountStatusList: PromoDiscountStatusItem[] = [
      { id: TPromoStatus.Active,   name: 'Active' },
      { id: TPromoStatus.FutureDated, name: 'Future Dated' },
      { id: TPromoStatus.Draft,    name: 'Draft' },
      { id: TPromoStatus.Deleted,  name: 'Deleted' },
      { id: TPromoStatus.Expired,  name: 'Expired' },
    ];
    promoDiscountStatusList.forEach(mapping => {
      this.tableCacheService.set(TABLE_PROMOTION_DISCOUNT_STATUS, mapping.id, mapping);
    });

  }

  //#endregion --- Seed Data ---

  //#region --- Mock data ---

  private getMockPromoPriceReductionList(): Observable<IPromoPriceReduction[]> {
    const promoPriceReductionList: PromoPriceReductionItem[] = [
      {id: 1, code: 'Promotion', description: 'Promotion'},
      {id: 2, code: 'Discount', description: 'Discount'},
    ];
    promoPriceReductionList.sort((a, b) => a.code.localeCompare(b.code));

    promoPriceReductionList.forEach((promoPriceReduction: IPromoPriceReduction) => {
      this.tableCacheService.set(TABLE_PROMO_PRICE_REDUCTION, promoPriceReduction.id, promoPriceReduction);
    });
    return of(promoPriceReductionList);
  }

  private getMockPromoBillingFrequencyList(): Observable<IPromoBillingFrequency[]> {
    const promoBillingFrequencyList: IPromoBillingFrequency[] = [
      {id: 1, code: 'OnceOff', description: 'Once Off'},
      {id: 2, code: 'Monthly', description: 'Monthly'},
      {id: 3, code: 'Annual', description: 'Annual'},
      {id: 4, code: 'ChangeOfService', description: 'Change Of Service'},
      {id: 5, code: 'ChangeOfISP', description: 'Change Of ISP'},
      {id: 6, code: 'Subscription', description: 'Subscription'},
      {id: 7, code: 'DayBundle', description: 'Day Bundle'},
    ];
    promoBillingFrequencyList.sort((a, b) => a.code.localeCompare(b.code));

    promoBillingFrequencyList.forEach((promoBillingFrequency: IPromoBillingFrequency) => {
      this.tableCacheService.set(TABLE_PROMO_BILLING_FREQUENCY, promoBillingFrequency.id, promoBillingFrequency);
    });

    return of(promoBillingFrequencyList);
  }

  private getMockPromoCategoryList(): Observable<IPromoCategory[]> {
    // Fetch the Promo Category List
    const promoCategoryList: IPromoCategory[] = [
      {
        id: 1, category_name: 'ACP Discount', line_item_description: 'ACP Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 2, category_name: 'Billing Issue - Set nr of Months', line_item_description: 'Billing Issue',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 3, category_name: 'Billing Issue - Open Ended', line_item_description: 'Billing Issue',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 4, category_name: 'Bulk Uptake Discount', line_item_description: 'Bulk Uptake Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 5, category_name: 'Bulk Uptake Promo', line_item_description: 'Bulk Uptake Promo',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 1, price_type_id: 2,
      },
      {
        id: 6, category_name: 'Employee Discount', line_item_description: 'Employee Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 7, category_name: 'Free Month', line_item_description: 'Free Month',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 8, category_name: 'Promo Free Month', line_item_description: 'Free Month',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 1, price_type_id: 2,
      },
      {
        id: 9, category_name: 'Historic Discount', line_item_description: '',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 10, category_name: 'Holiday Package', line_item_description: 'Holiday Package',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 11,
        category_name: 'Monthly Installation Waiver - for installation pay-off service combo prices only',
        line_item_description: 'Monthly Installation Waiver',
        benefit_count_applicable: false,
        query_type_id: 1,
        promo_discount_type_id: 2,
        price_type_id: 2,
      },
      {
        id: 12, category_name: 'Non-profit Discount', line_item_description: 'Non-profit Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 13, category_name: 'Other - Set nr of Months', line_item_description: '',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 14, category_name: 'Other - Open Ended', line_item_description: '',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 15, category_name: 'Promotion - Open Ended', line_item_description: 'Promotional Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 1, price_type_id: 2,
      },
      {
        id: 16, category_name: 'Promotion - Set nr of Months', line_item_description: 'Promotional Discount',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 1, price_type_id: 2,
      },
      {
        id: 17, category_name: 'Retention - Set nr of Months', line_item_description: 'Retention Discount',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 18, category_name: 'Retention - Open Ended', line_item_description: 'Retention Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 19, category_name: 'Service Credit - Set nr of Months', line_item_description: 'Service Credit',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 20, category_name: 'Service Credit - Open Ended', line_item_description: 'Service Credit',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 21, category_name: 'Static Ip - Bolt-on', line_item_description: 'Static Ip',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 22,
        category_name: 'Monthly Static Ip Removal - for static ip/service combo prices only',
        line_item_description: 'Static Ip Removed',
        benefit_count_applicable: false,
        query_type_id: 1,
        promo_discount_type_id: 2,
        price_type_id: 2,
      },
      {
        id: 23, category_name: 'Veteran Discount', line_item_description: 'Veteran Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 24, category_name: 'Voip - Bolt-on', line_item_description: 'Voip',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
      {
        id: 25,
        category_name: 'Monthly Voip Removal - for voip/service combo prices only',
        line_item_description: 'Voip Fee Removed',
        benefit_count_applicable: false,
        query_type_id: 1,
        promo_discount_type_id: 2,
        price_type_id: 2,
      },
      {
        id: 26, category_name: 'Day-Bundle - Set nr of Months', line_item_description: 'Day-Bundle Discount',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 8,
      },
      {
        id: 27, category_name: 'Day-Bundle - Set nr of Months', line_item_description: 'Day-Bundle Promotion',
        benefit_count_applicable: true, query_type_id: 1, promo_discount_type_id: 1, price_type_id: 8,
      },
      {
        id: 28, category_name: 'Installation/Once Off Discount', line_item_description: 'Installation Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 1,
      },
      {
        id: 29, category_name: 'Day-Bundle - Upfront', line_item_description: 'Upfront Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 1, price_type_id: 8,
      },
      {
        id: 30, category_name: 'Recurring - Upfront', line_item_description: 'Upfront Discount',
        benefit_count_applicable: false, query_type_id: 1, promo_discount_type_id: 2, price_type_id: 2,
      },
    ];
    promoCategoryList.sort((a, b) => a.category_name.localeCompare(b.category_name));

    promoCategoryList.forEach((promoCategory: IPromoCategory) => {
      this.tableCacheService.set(TABLE_PROMO_CATEGORY, promoCategory.id, promoCategory);
    });

    return of(promoCategoryList);
  }

  private getMockPromoReductionTypeList(): Observable<IPromoReductionType[]> {
    const promoReductionTypeList: IPromoReductionType[] = [
      {id: 1, code: 'Subtract', description: 'Adjustment by Subtraction'},
      {id: 2, code: 'Substitute', description: 'Adjustment by Substition'},
      {id: 3, code: 'Percentage', description: 'Adjustment by Percentage'},
    ];
    promoReductionTypeList.sort((a, b) => a.description.localeCompare(b.description));

    promoReductionTypeList.forEach((promoReductionType: IPromoReductionType) => {
      this.tableCacheService.set(TABLE_PROMO_REDUCTION_TYPE, promoReductionType.id, promoReductionType);
    });

    return of(promoReductionTypeList);
  }

  private getMockPromoPeriodTypeList(): Observable<IPromoPeriodType[]> {
    const promoPeriodTypeList: IPromoPeriodType[] = [
      {id: 1, name: 'Monthly'},
    ];
    promoPeriodTypeList.sort((a, b) => a.name.localeCompare(b.name));

    promoPeriodTypeList.forEach((promoPeriodType: IPromoPeriodType) => {
      this.tableCacheService.set(TABLE_PROMO_PERIOD_TYPE, promoPeriodType.id, promoPeriodType);
    });

    return of(promoPeriodTypeList);
  }

  private getMockPromoCodeUsageList(): Observable<IPromoCodeUsage[]> {
    const promoCodeUsageList: IPromoCodeUsage[] = [
      {id: '1507017c-2bd1-466b-8827-9b7e67c1358a', name: 'One Time Use'},
      {id: '4089ce6b-3778-41f7-9756-78122f352164', name: 'Multiple Use'},
    ];
    promoCodeUsageList.sort((a, b) => a.name.localeCompare(b.name));

    promoCodeUsageList.forEach((promoCodeUsage: IPromoCodeUsage) => {
      this.tableCacheService.set(TABLE_PROMO_CODE_USAGE, promoCodeUsage.id, promoCodeUsage);
    });

    return of(promoCodeUsageList);
  }

  private getMockPromoAppliedByList(): Observable<IPromoAppliedBy[]> {
    const promoAppliedByList: IPromoAppliedBy[] = [
      {id: '2f5f92aa-ee11-4609-91f9-06809d15b74d', name: 'Customer'},
      {id: '2e05188d-7877-48d8-9767-901c8bb34b76', name: 'Sales Agent'},
    ];
    promoAppliedByList.sort((a, b) => a.name.localeCompare(b.name));

    promoAppliedByList.forEach((promoAppliedBy: IPromoAppliedBy) => {
      this.tableCacheService.set(TABLE_PROMO_APPLIED_BY, promoAppliedBy.id, promoAppliedBy);
    });

    return of(promoAppliedByList);
  }

  private getMockPromoAreaList(): Observable<PromoAreaTreeItem[]> {
    let areaTreeList: PromoAreaTreeItem[] = [
      {
        children: [
          {
            children: [],
            id: "4058bf01-22d9-4d80-80ec-37263bd71e33",
            name: "Albuquerque OLT 1",
            has_children: false,
          },
        ],
        id: "e67e463c-8069-4618-903e-c445f9344402",
        name: "Albuquerque",
        has_children: true,
      },
    ];

    areaTreeList.forEach((area) => {
      this.tableCacheService.set(TABLE_PROMO_AREA, area.id, area);
    });

    return of(areaTreeList)
  }

  private getMockPromoProductList(): Observable<IPromoProduct[]> {
    const promoProductList: IPromoProduct[] = [
      {id: '4ca6a1af-7e60-4460-9e74-e15efa455f78', name: '1 Gig '},
      {id: 'be17c24f-ea1f-4718-94e2-100f832c8a3a', name: '1 Gig (Legacy)'},
      {id: 'fb14f74b-6bb1-4d18-b053-deb4a46ffe43', name: '1 Gig Dedicated'},
      {id: 'f67ac2b6-f981-4345-a0f1-81c4a72c7e42', name: '1 Gig with Phone Line'},
      {id: 'abdafa2f-c6d5-48f1-8be3-67b5b2568836', name: '100 Dedicated'},
      {id: 'ac5dbee5-88f7-4925-9dfe-3a9a0bac6c6e', name: '100 Mbps Business'},
      {id: '0c6a75d0-305a-4d14-a318-1478847b9c93', name: '100 Mbps Business with Phone Line'},
      {id: 'acf5e1d4-69f5-4b7b-bbfc-03b65a07ddb7', name: '1Gig Business '},
      {id: '27055b4b-d709-4fef-8fce-4e4461d9b4dd', name: '250 Mbps'},
      {id: 'ad0b8efa-20ed-41e2-bfcf-d48761ca82f8', name: '250 Mbps '},
      {id: '78069fc1-21df-4fd1-b6fc-d72bcc76b069', name: '250 Mbps  -  $ 10 p/m over 24 months'},
      {id: '37f59585-577c-4cef-ac49-a79a45b3094e', name: '250 Mbps Monthly Installation Fee with Phone Line'},
      {id: '3ab229a5-7bca-4c29-8334-d5de6ceaaca1', name: '250 Mbps with Phone Line'},
      {id: 'd16d76ce-8b12-4df1-91db-1848efcdbd44', name: '250 Mbps: Monthly Installation Fee'},
      {id: 'ab1f25da-ac0e-487c-a071-2e2e7071b423', name: '250MB including VOIP with no install fee'},
      {id: '6ddf668e-1e13-4649-8ba3-960dc9bf36bc', name: '300 Dedicated'},
      {id: '0d796544-89ee-4637-a2f9-0bd9576d29e1', name: '300 Mbps Business'},
      {id: '88a24d29-b0d5-4fea-acaa-039ae56a7ec1', name: '500 Dedicated'},
      {id: '0f7b40d6-5b0a-4471-a18b-f190fb5955ca', name: '500 Mbps Business'},
      {id: '2561fcf9-3a23-4046-a5bb-e174c575f44a', name: 'Avington - 1 Gig Ultra'},
      {id: '05cb072f-f921-4ab9-8b79-3eb986aff48c', name: 'Backhaul'},
      {id: '8a75c70c-beb1-48e6-b214-e7c4b5215a65', name: 'Delete'},
      {id: 'ef3e2334-0b2d-422c-bd3a-4160dddc5c1a', name: 'Fiber Ready (No Active Services)'},
      {id: '257cfee1-3b7d-407e-8dce-545a7eb14a2b', name: 'Fiber to the Business'},
      {id: '85e35e0f-8e00-4590-9214-6741903185e0', name: 'Fiber to the Home 50/50'},
      {id: '3be8e61a-550b-4090-bc9d-0c8db6a360e0', name: 'FTTB - Enterprise 100/100'},
      {id: '1d32d261-8c38-4ad1-917d-8384904066c9', name: 'FTTB - Enterprise 1000/1000'},
      {id: 'a6e31752-ea11-4d4d-bf7d-758e4bbd7a36', name: 'FTTB - Enterprise 1000/1000- Duplicate- Delete please'},
      {id: '931fc5bd-9fad-4903-9aa1-9606b5ed65ef', name: 'FTTB - Enterprise 300/300'},
      {id: 'ecbe2c2a-1215-41f6-8ea3-89fa011e26fc', name: 'FTTB - Enterprise 500/500'},
      {id: '458b41e4-f117-4ca9-ac95-96d131a3bd53', name: 'FTTB 100Mb/100Mb -Voice'},
      {id: '895883c1-c834-4da2-a266-42668ecc3c95', name: 'FTTB 100Mb/Voice'},
      {id: '6e90477c-98a7-417c-96d4-b8571e05ec4d', name: 'FTTB 50/50'},
      {id: 'fb4a57f0-a014-49b6-ab91-42021cdcd89d', name: 'FTTB-SMB 100/100'},
      {id: '2499e1fb-410c-4d93-b490-c5547ae7ab29', name: 'FTTB-SMB 1000/1000'},
      {id: '3575f51d-742a-4413-9add-dc50ee268f10', name: 'FTTB-SMB 300/300'},
      {id: 'dab4c0f9-2073-4b86-a9b2-2937e4ca2e00', name: 'FTTB-SMB 500/500'},
      {id: 'a35e2486-c34c-4aca-b71b-fd34fab10f8c', name: 'FTTH 1000'},
      {id: 'dbf71560-52bd-4696-a47f-dc2e234d1408', name: 'FTTH 1000/1000'},
      {id: 'c78b076d-189f-4f3a-83b7-502a9ff83fe3', name: 'FTTH 1000/1000 New'},
      {id: '1085edbb-22f6-4439-b8b3-2ef97863ac41', name: 'FTTH 250/250'},
      {id: '01841340-4477-4a2f-b327-1e5a49f8b364', name: 'FTTH 250/250 with Installation Fee'},
      {id: '7863a4dd-efd9-4f66-89b9-ccf5b2c65431', name: 'FTTH 250/250-Archived'},
      {id: 'dce19f5f-963e-44c2-abc1-3bd204462e44', name: 'FTTH 50/50'},
      {id: '71531e66-7936-4fdc-a4d7-1d0a13ee089d', name: 'FTTH: Employee Benefit Home Service'},
      {id: '82904a7d-995a-46db-962f-122e8cd42661', name: 'FTTH: Employee Benefit Home Service (No Charge)'},
      {id: '51c9d13c-3194-4702-9dd4-5952ac800fea', name: 'Internal Only Test'},
      {id: '7aff025c-3d49-43cd-ab70-9027b37bf2e5', name: 'INTERNAL PRODUCT'},
      {id: 'fdec6244-c9b6-417d-94a4-35c722c1d5f9', name: 'NID Only'},
      {id: '271cc0de-dfbc-453f-a52d-3e5d16e11440', name: 'Portal Fee'},
      {id: '938a5ecd-f1e1-4262-aad5-65789da4f7a9', name: 'Quarter Gig with $200 Installation Fee'},
      {id: 'b03ab5f0-84ef-452f-9a25-81b03206e56e', name: 'Zero Rated Services'},
    ];

    promoProductList.sort((a, b) => a.name.localeCompare(b.name));

    promoProductList.forEach((promoProduct) => {
      this.tableCacheService.set(TABLE_PROMO_PRODUCT, promoProduct.id, promoProduct);
    });

    return of(promoProductList);
  }

  private getMockPromoBillingRuleList(): Observable<IPromoBillingRule[]> {
    const promoBillingRuleList: IPromoBillingRule[] = [
      {
        id: 1,
        name: 'Monthly',
        // charges_activation_pro_rata: null,
        // charges_installation : null,
        // has_upfront : null,
        // billing_scheme_product_type : null,
        // has_splits : null,
      },
      {
        id: 2,
        name: 'Once-off',
        // charges_activation_pro_rata: null,
        // charges_installation : null,
        // has_upfront : null,
        // billing_scheme_product_type : null,
        // has_splits : null,
      },
    ];
    promoBillingRuleList.sort((a, b) => a.name.localeCompare(b.name));

    promoBillingRuleList.forEach((promoBillingRule) => {
      this.tableCacheService.set(TABLE_PROMO_BILLING_RULE, promoBillingRule.id, promoBillingRule);
    });

    return of(promoBillingRuleList);
  }

  private getMockPromoServiceStatusList(): Observable<IPromoServiceStatus[]> {
    const promoServiceStatusList: IPromoServiceStatus[] = [
      {id: '1', name: 'Service Status 1'},
      {id: '2', name: 'Service Status 2'},
      {id: '3', name: 'Service Status 3'},
      {id: '4', name: 'Service Status 4'},
    ];
    promoServiceStatusList.sort((a, b) => a.name.localeCompare(b.name));

    promoServiceStatusList.forEach((promoServiceStatus) => {
      this.tableCacheService.set('PromoServiceStatus', promoServiceStatus.id, promoServiceStatus);
    });
    return of(promoServiceStatusList);
  }

  private initPromoDiscountData(): void {
    const data: PromoDiscountDataItem[] = [
      new PromoDiscountDataItem({
        id: '8ec194d4-2ecf-4a29-8800-88b9a4a711fc',
        name: '1Gig Under 1K',
        promo_code: 'GIGFORLESS',
        start_date_value: new Date(2022, 1, 21),
        end_date_value: new Date(2022, 1, 31),
        service_usage_count: 17,
        promo_discount_status_id: TPromoStatus.Active,
        status: 'Active',
        collector_id: '1',
        promo_discount_type_id: 1,
        promo_category_id: 5,
        eligibility_filter_id: '1',
        description: '1Gig Under 1K - Description 1',
        line_item_message: '1Gig Under 1K -Line Item Message 1',
        published_date: new Date(2022, 1, 19),
        auto_enlist: false,
        gl_code: '1000000-000',
        goal_or_marketing_message: '1Gig Under 1K -Goal or Marketing Message 1',
        price_type_id: 2,
        price_type_name: 'Price Type Name 1',
        waive_activation_pro_rata: true,
        adjustment_type_id: 1,
        adjustment_type_name: 'Adjustment Type Name 1',
        adjustment_value: 10,
        period_length: 1,
        collector_name: 'Collector Name 1',
        promo_discount_type_name: 'Discount Type Name 1',
        promo_category_name: 'Category Name 1',
        category_query_type_id: 1,
      }),
      new PromoDiscountDataItem({
        id: '6ec29e26-feec-43ac-a5e1-f09756472a26',
        name: 'April 2021 NoJoke Promo',
        promo_code: 'NOJOKE',
        start_date_value: new Date(2021, 3, 1),
        service_usage_count: 2017,
        promo_discount_status_id: TPromoStatus.Draft,
        end_date_value: new Date(2021, 4, 30),
        status: 'In-active',
        collector_id: '2',
        promo_discount_type_id: 1,
        promo_category_id: 5,
        eligibility_filter_id: '2',
        description: 'April 2021 NoJoke Promo - Description 2',
        line_item_message: 'April 2021 NoJoke Promo -Line Item Message 2',
        published_date: new Date(2022, 0, 19),
        auto_enlist: true,
        gl_code: '1000000-000',
        goal_or_marketing_message: 'April 2021 NoJoke Promo -Goal or Marketing Message 2',
        price_type_id: 2,
        period_length: 1,
        category_query_type_id: 1,

        price_type_name: 'Price Type Name 2',
        waive_activation_pro_rata: true,
        adjustment_type_id: 1,
        adjustment_type_name: 'Adjustment Type Name 2',
        adjustment_value: 10,
        collector_name: 'Collector Name 2',
        promo_discount_type_name: 'Discount Type Name 2',
        promo_category_name: 'Category Name 2',
      }),
      new PromoDiscountDataItem({
        id: 'e6698a2d-f030-4279-8209-eb891053688e',
        name: '1 Month Free',
        promo_code: '!MONTH',
        start_date_value: new Date(2024, 0, 21),
        end_date_value: new Date(2024, 0, 31),
        service_usage_count: null,
        promo_discount_status_id: TPromoStatus.Draft,
        status: 'Draft',
        collector_id: '3',
        promo_discount_type_id: 1,
        promo_category_id: 5,
        eligibility_filter_id: '3',
        description: '1 Month Free - Description 3',
        line_item_message: '1 Month Free -Line Item Message 3',
        published_date: new Date(2022, 1, 19),
        auto_enlist: true,
        gl_code: '1000000-000',
        goal_or_marketing_message: '1 Month Free -Goal or Marketing Message 3',
        price_type_id: 2,
        period_length: 1,
        category_query_type_id: 1,

        price_type_name: 'Price Type Name 1',
        waive_activation_pro_rata: true,
        adjustment_type_id: 3,
        adjustment_type_name: 'Adjustment Type Name 3',
        adjustment_value: 10,
        collector_name: 'Collector Name 3',
        promo_discount_type_name: 'Discount Type Name 3',
        promo_category_name: 'Category Name 3',
      }),
      new PromoDiscountDataItem({
        id: '8a1d1cfa-e9e2-4ad5-bd14-f999832bc5a0',
        name: 'Valentine Day',
        promo_code: 'SWEETHEART',
        start_date_value: new Date(2024, 2, 1),
        service_usage_count: 2000000,
        promo_discount_status_id: TPromoStatus.Deleted,
        end_date_value: new Date(2024, 2, 14),
        status: 'Deleted',
        collector_id: '4',
        promo_discount_type_id: 1,
        promo_category_id: 5,
        eligibility_filter_id: '4',
        description: 'Valentine Day - Description 4',
        line_item_message: 'Valentine Day -Line Item Message 4',
        published_date: new Date(2024, 1, 31),
        auto_enlist: true,
        gl_code: '1000000-000',
        goal_or_marketing_message: 'Valentine Day -Goal or Marketing Message 4',
        price_type_id: 2,
        period_length: 1,
        category_query_type_id: 1,

        price_type_name: 'Price Type Name 4',
        waive_activation_pro_rata: true,
        adjustment_type_id: 1,
        adjustment_type_name: 'Adjustment Type Name 4',
        adjustment_value: 10,
        collector_name: 'Collector Name 4',
        promo_discount_type_name: 'Discount Type Name 4',
        promo_category_name: 'Category Name 4',
      }),
    ];

    data.sort((a, b) => a.name.localeCompare(b.name));

    data.forEach((promoDiscountDataItem) => {
      const promoDiscountItem = this.transformPromoDiscountDataItemToPromoDiscount(promoDiscountDataItem);
      this.tableCacheService.set(TABLE_PROMOTION_DISCOUNT, promoDiscountItem.promo_discount_id, promoDiscountItem);
    });
  }

  private getMockPromotionDiscountData(searchRequest: PromoDiscountSearchRequestDto): Observable<IPagedResponse<PromoDiscountDataItem>> {
    const cacheData = this.tableCacheService.getAll<PromoDiscountItem>(TABLE_PROMOTION_DISCOUNT);

    const data: PromoDiscountDataItem[] = [];
    cacheData.forEach((promoDiscountItem) => {
      const promoDiscountDataItem = this.transformPromoDiscountItemToPromoDiscountDataItem(promoDiscountItem);
      data.push(promoDiscountDataItem);
    });

    data.sort((a, b) => a.name.localeCompare(b.name));

    const start = (searchRequest.page_number - 1) * searchRequest.page_size;
    const response = data.slice(start, start + searchRequest.page_size);

    return of({
      items: response,
      page: searchRequest.page_number,
      count: response.length,
      total: data.length,
    });
  }
  //#endregion --- Mock
}
