import { NavigationService, StorageService } from '@aex/ngx-toolbox';
import { ENVIRONMENT_CONFIG_TOKEN, IEnvironment } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IPortalEnvironment } from '../../environments/environment-type';

@Injectable()
export class PortalConfigService extends ConfigService {

	constructor(
		http: HttpClient,
		navigationService: NavigationService,
		storageService: StorageService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
	) {
		super(http, navigationService, storageService, environment);
	}

	private _userId: string;

	public get userId(): string {
		return this._userId;
	}

	public set userId(value: string) {
		this._userId = value;
	}

	public get operator(): string {
		return this._fnoName;
	}

	public override getConfigName(): string {
		return this.environment.override?.configName || this.environment.configName || this.environment.name;
	}

	protected getConfigFile(operator: string): string {
		return this.environment.override?.configFile ?? operator;
	}

	public get bypassCookieAuthFlow(): boolean {
		const environment = this.environment as IPortalEnvironment;
		return environment?.bypassCookieAuthFlow;
	}
}
