import {
	Directive,
	Injector,
	ViewChild,
} from "@angular/core";
import {
	ActivatedRoute,
	Router,
} from "@angular/router";
import {
	FilterableSettings,
	GridDataResult,
	PageChangeEvent,
	PagerSettings,
	SelectableMode,
	SelectableSettings,
} from "@progress/kendo-angular-grid";
import { FilterExpression } from "@progress/kendo-angular-filter";
import {
	CompositeFilterDescriptor,
	SortDescriptor,
} from "@progress/kendo-data-query";
import {
	copyIcon,
	fileAddIcon,
	fileCsvIcon,
	infoCircleIcon,
	trashIcon,
} from '@progress/kendo-svg-icons';
import { instanceToInstance } from "class-transformer";
import { ToastrService } from "ngx-toastr";

import { AdvanceSearchGridComponent, GridStateViewService } from '@aex/shared/ui-components';

import { DialogManagerService } from "../services";

import {
	IYesNo,
	restoreGridVisibleColumnOrder,
} from "../helpers/kendo-helper";
import { IPagedData } from "../interfaces/general";

import {
	ActionItem,
	GridColumnItem,
	GridState,
	IAdvanceGridSearch,
	isGridStateIdentical,
	LoadGridStateRequest,
	PAGE_SIZES,
} from "../interfaces/kendo-grid";

import { CanComponentDeactivate } from "../interfaces/unsaved-changes.guard";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// T is the name of the grid data record and F is the class of the AdvanceFilterItem
@Directive()
export abstract class BaseGridComponent<T, F> implements CanComponentDeactivate{

	@ViewChild(AdvanceSearchGridComponent) advanceSearchGridComponent: AdvanceSearchGridComponent;

	public readonly svgCsvIcon = fileCsvIcon;
	public readonly svgCopyIcon = copyIcon;
	public readonly svgTrashIcon = trashIcon;
	public readonly svgFileAddIcon = fileAddIcon;
	public readonly svgInfoCircleIcon = infoCircleIcon;

	protected readonly toastr: ToastrService = this.injector.get(ToastrService);
	protected readonly dialogManagerService: DialogManagerService = this.injector.get(DialogManagerService);
	protected readonly gridStateViewService:GridStateViewService = this.injector.get(GridStateViewService);
	protected readonly router: Router = this.injector.get(Router);
	protected readonly route: ActivatedRoute = this.injector.get(ActivatedRoute);

	protected constructor(
		private readonly injector: Injector,
	){
	}


	public canDeactivate(): Observable<boolean> | boolean {
		if (this.loadedGridState === null)
			return true;

		// Get Current grid state
		const currentGridState = this.advanceSearchGridComponent.currentGridState;
		currentGridState.advanceFilterActive = this.advanceFilterActive;
		currentGridState.hasAdvancedSearch = true;
		currentGridState.advGridSearch = this.advanceSearchFilterItem;

		// Compare with loaded Grid State
		const isGridStateSame = isGridStateIdentical(this.loadedGridState, currentGridState);
		if (isGridStateSame)
			return true;

		const canDeactivateMessage = `Grid State has changed. Do you want to save the changes?`;
		return this.dialogManagerService.openYesNoDialog(
			'Grid State Changes',
			canDeactivateMessage,
		).pipe(
			map((confirm: boolean) => !confirm), // Invert the result, as `true` means canDeactivate
		);
	}

	public get gridData(): T[]
	{
		return this.pagedData.items;
	}

	public pagedData: IPagedData<T> = {
		page: 1,
		count: 0,
		total: 0,
		items: [],
	};

	private _filteredGridData: T[] = [];
	public get filteredGridData(): T[] {
		return this._filteredGridData;
	};

	public totalRecordCount: number = 0;

	public gridDataResult : GridDataResult = {
		data: this.filteredGridData,
		total: this.totalRecordCount,
	};

	public set filteredGridData(value: T[]) {
		this._filteredGridData = value;
		const gridDataResult = {
			data: this.filteredGridData,
			total: this.totalRecordCount,
		};
		this.gridDataResult = { ...gridDataResult };
	}

	public columns: GridColumnItem[] = [];
	public selectableColumns : string[] = [];
	public filteredGridColumns : string[] = [];
	public selectedColumns: string[] = [];
	public visibleColumns: GridColumnItem[] = [];

	// Pagination
	public pageSize = 20;
	public buttonCount = 5;
	public sizes = PAGE_SIZES
	public skip = 0;
	public currentPage = 1;
	public pageSettings : PagerSettings = {
		buttonCount: this.buttonCount,
		info: true,
		type: 'numeric',
		pageSizes: this.sizes,
		previousNext: true,
	}

	// Advance Filter
	public advanceFilterActive : boolean = false;
	public advanceSearchFilterItem : F | null = null;

	public toggleAdvanceSearch : boolean = false;

	// Grid Filtering
	public sort: SortDescriptor[] = [ { field: 'name', dir: 'asc'} ]; 		// Grid Sort Value

	public showXLStyleFilter: FilterableSettings = 'menu';            		// Show the XL style filter

	public searchKeyword: string = '';                                		// Search keyword

	protected readonly defaultFilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

	public kendoGridSelectKeyId : string = this.getKendoGridSelectKeyId(); // Key Id for the Kendo Grid Select

	public gridFilterValue: CompositeFilterDescriptor = { ...this.defaultFilter };
	public gridFilterFields : FilterExpression[]= []
	public showCheckboxColumn : boolean = this.getShowCheckboxColumn();   // Show the checkbox column
	public allSelected: boolean = false;                                  // All rows selected
	public gridSelectable : SelectableMode = this.checkboxSelectable();   // 'single' | 'multiple'
	public selectedItems : string[] = [];                                 // Selected row items
	public selectableSettings : SelectableSettings = {
		checkboxOnly: this.showCheckboxColumn,
		mode: this.gridSelectable,
	}

	public hideQuickToolsList : IYesNo[] = [
		{ id: true, name: 'Show Quick Tools' },
		{ id: false, name: 'Hide Quick Tools' },
	];

	public quickButtonDisabled: boolean = true;
	public hideQuickTools: boolean = true;

	protected readonly exportFileName = this.getExportFileName();

	public gridPageChangeEvent(event: PageChangeEvent) : void {
		this.pageSize = event.take;
		const currentPage = Math.floor(event.skip / this.pageSize) + 1; // Calculate the page number
		this.skip = event.skip;
		this.currentPage = currentPage;
		this.fetchGridData(this.currentPage, this.pageSize);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public setAdvanceSearchFilterItemEvent(advancedSearchFilterItem: any): void
	{
		this.advanceSearchFilterItem = advancedSearchFilterItem;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public nameHyperlinkActionEvent(event: any): void {
		var	actionItem = event as ActionItem<T>;
		this.nameHyperlinkAction(actionItem.fieldName, actionItem.dataItem);
	}

	// Grid State
	public readonly gridViewId : string = this.getGridViewName();
	public gridState : GridState = new GridState();                   // Grid State
	public loadedGridState : GridState | null = null;

	public saveGridStateEvent(gridState : GridState): void {
		gridState.advGridSearch = instanceToInstance(this.advanceSearchFilterItem);
		this.gridState = gridState;
		this.gridStateViewService.saveGridViewState(
			this.gridViewId,
			gridState,
		);
	}

	protected restoreLoadedGridState(): void {
		if (!this.loadedGridState)
			return;
		this.visibleColumns = restoreGridVisibleColumnOrder(this.columns, this.loadedGridState.visualColumnOrder);
	}

	public filterAdvSearchClick() : void {
		this.advanceFilterActive = true;
		this.currentPage = 1;
		this.toggleAdvanceSearch = false;
		this.fetchGridData(this.currentPage, this.pageSize);
	}

	protected clearFilterAdvSearchClick() : void {
		this.clearAdvanceSearchFilter();
	}
	public cancelAdvSearchClick() : void {
		this.clearFilterAdvSearchClick();
		this.toggleAdvanceSearch = false;
		this.advanceFilterActive = false;
		this.currentPage = 1;
		this.fetchGridData(this.currentPage, this.pageSize);
	}

	public checkBoxSelectionEvent(selectedItems: string[]):void{
		this.selectedItems = selectedItems;
		this.quickButtonDisabled = this.selectedItems.length === 0;
		this.onCheckBoxSelectionChange();
	}

	public loadGridStateEvent(loadGridStateRequest: LoadGridStateRequest): void {
		const gridState = loadGridStateRequest.gridState;
		if (gridState.hasAdvancedSearch) {
			this.advanceFilterActive = gridState.advanceFilterActive;
			this.setAdvanceSearchFilterItem(gridState.advGridSearch);
		}

		this.loadedGridState = instanceToInstance(gridState);

		console.log('this.loadedGridState', this.loadedGridState);
		// Restore the grid data and update the visible columns?
		if (!loadGridStateRequest.reloadData)
			return;

		this.fetchGridData(gridState.currentPage, gridState.pageSize);
	}

	// Abstract Methods
	protected abstract getGridViewName() : string;
	protected abstract getShowCheckboxColumn() : boolean;
	protected abstract checkboxSelectable() : SelectableMode;
	protected abstract getExportFileName() : string;
	protected abstract fetchGridData(pageNumber: number, pageSize: number): void;
	protected abstract getKendoGridSelectKeyId() : string;
	public abstract clearAdvanceSearchFilter() : void;
	protected abstract setAdvanceSearchFilterItem(value: IAdvanceGridSearch) : void;
	protected abstract nameHyperlinkAction(field:string, action: T): void;
	protected onCheckBoxSelectionChange() : void {/* do nothing by default*/}

}
