<div class="page-container">
	<div [hidden]="!hideQuickTools" >

		<div class="form-row" kendoTooltip position="top">
			<!-- Grid View Management -->
			<div class="flex-1">
				<app-grid-state-view
					[gridViewName]="gridViewId"
					[saveGridState]="gridState"
					(saveGridStateEvent)="saveGridState($event)"
					(loadGridStateEvent)="loadGridState($event)"
				></app-grid-state-view>
			</div>

			<div class="flex flex-1 justify-end gap-2">
				<!-- Quick Actions Button -->
				<kendo-dropdownbutton
					title="Quick Actions to Duplicate, Delete or Bulk Apply"
					themeColor="primary"
					[data]="quickActionOptions"
					(itemClick)="quickActionItemClick($event)"
					textField="description"
					[disabled]="quickButtonDisabled">
					Quick Actions
				</kendo-dropdownbutton>
				<kendo-dropdownbutton
					title="Export grid data to Excel csv format "
					[svgIcon]="svgDownloadIcon"
					[data]="exportOptions"
					(itemClick)="exportGridDataItemClick($event)"
					textField="description">
					Export
				</kendo-dropdownbutton>
			</div>

		</div>
	</div>

	<!-- Advance Filter Window -->
	<kendo-expansionpanel
		[expanded]="isToggle"
		(expand)="onAdvSearchExpand()"
		(collapse)="onAdvSearchCollapse()"
	>
		<ng-template kendoExpansionPanelTitleDirective>
			<div class="header-content" style="color: blue">
				<kendo-svgicon
					[icon]="svgFilterIcon"
					[style.marginRight.px]="10"
				></kendo-svgicon>
				<span class="text-blue-300">Advanced Search</span>
			</div>
		</ng-template>
		<ng-content>

		</ng-content>
	</kendo-expansionpanel>

	<div>
		<!-- Grid -->
		<kendo-grid
			#dataGrid
			[data]="gridDataResult"
			[pageable]="pageSettings"
			[pageSize]="pageSize"
			[skip]="skip"
			[navigable]="true"
			[sortable]="true"
			[sort]="sort"
			[resizable]="true"
			[filterable]="showXLStyleFilter"
			[filter]="gridFilterValue"
			(filterChange)="filterChange($event)"
			[reorderable]="true"
			(columnReorder)="gridColumnReorderEvent($event)"
			(sortChange)="onGridSortChange($event)"
			(pageChange)="onGridPageChange($event)"
			[selectable]="selectableSettings"
			[kendoGridSelectBy]="selectGridKeyId"
			[(selectedKeys)]="selectedItems"
			(selectionChange)="checkBoxSelectionChange()">

			<!-- Grid Toolbar -->
			<ng-template kendoGridToolbarTemplate *ngIf="hideQuickTools">
				<div class="flex flex-row justify-start items-center w-full gap-4" kendoTooltip position="top">

					<!-- Grid Column Selection-->
					<div class="flex-1 flex-row items-center justify-center">

						<kendo-multiselect
							kendoMultiSelectSummaryTag
							[data]="filteredGridColumns"
							[(ngModel)]="selectedColumns"
							(valueChange)="onGridColumnSelectionChange()"
							[clearButton]="true"
							[filterable]="true"
							(filterChange)="onFilteredGridColumnChange($event)"
							[checkboxes]="true"
							[autoClose]="false"
							title="Select Grid Columns to Display"
							style="width: 350px">
							<ng-template kendoSuffixTemplate>
								<button kendoButton
								        [svgIcon]="svgWindowRestoreIcon"
								        title="Reset Grid Columns"
								        (click)="resetGridColumns()"
								></button>
								<button kendoButton
								        [svgIcon]="svgSelectAllIcon"
								        title="Select ALL Grid Columns"
								        (click)="selectALLGridColumns()"
								></button>
							</ng-template>
						</kendo-multiselect>

					</div>

					<!-- Search Textbox -->
					<div class="form-row flex-1" style="background-color: blue">
						<kendo-textbox
							placeholder="Search Keyword"
							[(value)]="searchKeyword"
							(keydown)="handleKeydown($event)"
							class="flex-1">

							<!-- Prefix Template -->
							<ng-template kendoTextBoxPrefixTemplate>
								<kendo-svgicon title="Search for records containing the provided keyword" [icon]="svgSearchIcon"></kendo-svgicon>
							</ng-template>

							<!-- Suffix Template for Clear Button -->
							<ng-template kendoTextBoxSuffixTemplate *ngIf="searchKeyword">
								<button kendoButton
								        title = "Search using Keyword"
								        [svgIcon]="svgSearchIcon"
								        (click)="onSearchKeywordChange()">
								</button>
								<button kendoButton
								        title = "Clear Keyword Search"
								        [svgIcon]="svgXIcon"
								        (click)="clearSearch()">
								</button>
							</ng-template>

						</kendo-textbox>
					</div>
				</div>

			</ng-template>

			<!-- Conditionally Render Checkbox Column -->
			<kendo-grid-checkbox-column *ngIf="showCheckboxColumn" [width]="checkboxColumnWidth">
				<ng-template kendoGridHeaderTemplate>
					<input
						type="checkbox"
						kendoCheckBox
						[checked]="allSelected"
						(change)="toggleSelectAll($event)"
					/>
				</ng-template>
			</kendo-grid-checkbox-column>

			<!-- Grid Columns -->
			<kendo-grid-column
				*ngFor="let columnItem of visibleColumns"
				[field]="columnItem.field"
				[title]="columnItem.title"
				[hidden]="columnItem.hidden"
				[editable]="columnItem.editable"
				[filterable]="columnItem.filterable"
				[filter]="columnItem.filter"
				[width]="columnItem.width"
				[minResizableWidth]="minResizableWidth">
				<!-- Custom templates based on type -->

				<ng-template kendoGridCellTemplate let-dataItem let-field="column.field" >
					<ng-container [ngSwitch]="columnItem.data_type">

						<ng-container *ngSwitchCase="'boolean'">
							<kendo-checkbox class="items-center"
							                [checkedState]="dataItem[field]"
							>
							</kendo-checkbox>

							<!-- <span>{{ dataItem[field] ? 'Yes' : 'No' }}</span> -->
						</ng-container>
						<ng-container *ngSwitchCase="'date'">
							<span>{{ dataItem[field] | date: 'yyyy-MM-dd' }}</span>
						</ng-container>
						<ng-container *ngSwitchCase="'number'">
							<span>{{ dataItem[field] | number }}</span>
						</ng-container>
						<ng-container *ngSwitchCase="'string'">
							<ng-container [ngSwitch]="field">
								<!-- Hyperlink for Name -->
								<a *ngSwitchCase="'name'"
								   class="router-link"
								   style="color: blue;"
								   (click) = "nameHyperlinkAction(field, dataItem)">
									{{ dataItem[field] }}
								</a>
								<ng-container *ngSwitchCase="'status'">
									<div class="flex flex-row justify-start items-center">
										<div class="dot" [ngClass]="dataItem['promo_status_class']"></div>
										<span>{{ dataItem[field] }}</span>
									</div>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<span>{{ dataItem[field] }}</span>
								</ng-container>
							</ng-container>
							<ng-container *ngSwitchDefault>
								<span>{{ dataItem[field] }}</span>
							</ng-container>
						</ng-container>
					</ng-container>

				</ng-template>

			</kendo-grid-column>

			<!-- Custom Grid Commands: Report *** Until Report is defined! -->
			<!-- <kendo-grid-command-column title="Report" [width]="220">
				<ng-template kendoGridCellTemplate let-dataItem>
					<button kendoButton fillMode="outline" [svgIcon]="svgReportIcon" title="Promotion Report" (click)="promoDiscountReportClick(dataItem)">
						Report
					</button>
				</ng-template>

			</kendo-grid-command-column> -->

			<!-- Page Template -->
			<ng-template kendoPagerTemplate
			             let-totalPages="totalPages"
			             let-currentPage="currentPage">
				<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
				<kendo-pager-numeric-buttons
					[buttonCount]="buttonCount"
				></kendo-pager-numeric-buttons>
				<kendo-pager-next-buttons></kendo-pager-next-buttons>

				<!-- Center -->
				<kendo-pager-spacer></kendo-pager-spacer>
				<kendo-pager-page-sizes [pageSizes]="sizes" size="none"></kendo-pager-page-sizes>
				<kendo-pager-spacer></kendo-pager-spacer>

				<kendo-pager-info></kendo-pager-info>

			</ng-template>
		</kendo-grid>

	</div>

</div>
