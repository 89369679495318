import { AexNgToolboxModule } from '@aex/ngx-toolbox';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AdvanceSearchGridComponent } from "./advance-search-grid/advance-search-grid.component";
import { GridStateViewComponent } from "./grid-state-view/grid-state-view.component";
import { GridModule } from "@progress/kendo-angular-grid";
import { CheckBoxModule , FormFieldModule, TextBoxModule} from "@progress/kendo-angular-inputs";
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { ButtonModule , ButtonsModule} from "@progress/kendo-angular-buttons";
import { CardModule, LayoutModule} from "@progress/kendo-angular-layout";
import { DateInputsModule , DateTimePickerModule} from "@progress/kendo-angular-dateinputs";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { DropDownListModule , DropDownsModule} from "@progress/kendo-angular-dropdowns";
import { FilterModule } from "@progress/kendo-angular-filter";
import { FloatingLabelModule, LabelModule} from "@progress/kendo-angular-label";
import { IconsModule } from "@progress/kendo-angular-icons";
import { DateRangeSelectorComponent} from "./date-range-selector/date-range-selector.component";
import { BreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import { BreadCrumbModule} from "@progress/kendo-angular-navigation";

@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		RouterModule,
		AexNgToolboxModule.forChild(),
		TextBoxModule,
		TooltipModule,
		ButtonModule,
		ButtonsModule,
		BreadCrumbModule,
		CardModule,
		CheckBoxModule,
		DateInputsModule,
		DateTimePickerModule,
		DialogModule,
		DropDownsModule,
		DropDownListModule,
		FilterModule,
		FloatingLabelModule,
		FormFieldModule,
		GridModule,
		IconsModule,
		GridModule,
		LabelModule,
		LayoutModule,
	],
	declarations: [
		AdvanceSearchGridComponent,
		BreadcrumbComponent,
		DateRangeSelectorComponent,
		GridStateViewComponent,
	],
	exports: [
		AdvanceSearchGridComponent,
		BreadcrumbComponent,
		DateRangeSelectorComponent,
		GridStateViewComponent,
	],
})
export class SharedUiComponentsModule {}
