import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableCacheService {
  // A structure to store data organized by table names
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cache: { [table: string]: { [key: string|number]: any } } = {};

  /**
   * Add or update data for a specific table and key.
   * @param table The name of the table (or entity).
   * @param key The unique identifier for the cached entry.
   * @param value The data to cache.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set(table: string, key: string|number, value: any): void {
    if (!this.cache[table]) 
      this.cache[table] = {};    
    
    this.cache[table][key] = value;
  }

  /**
   * Retrieve cached data for a specific table and key.
   * @param table The name of the table.
   * @param key The unique identifier for the cached entry.
   * @returns The cached data or null if not found.
   */
  public get<T>(table: string, key: string|number): T | null {
    if (!this.has(table, key)) 
      throw new Error(`Key ${key} not found in table ${table}`);
    
    return this.cache[table]?.[key] ?? null;
  }

  /**
   * Retrieve all data for a specific table.
   * @param table The name of the table.
   * @returns An array of all cached entries for the table.
   */
  public getAll<T>(table: string): T[] {
    return Object.values(this.cache[table] || {});
  }

  /**
   * Check if a specific key exists in a table's cache.
   * @param table The name of the table.
   * @param key The unique identifier for the cached entry.
   * @returns True if the key exists, otherwise false.
   */
  public has(table: string, key: string|number): boolean {
    return !!this.cache[table]?.[key];
  }

  /**
   * Check if a specific table exists in table's cache.
   * @param table The name of the table.
   */
  public existTable(table: string): boolean {
    return !!this.cache[table];
  }

  /**
   * Clear a specific key from a table's cache.
   * @param table The name of the table.
   * @param key The unique identifier to remove.
   */
  public clear(table: string, key: string|number): void {
    if (this.cache[table]) 
      delete this.cache[table][key];    
  }

  /**
   * Clear all data for a specific table.
   * @param table The name of the table.
   */
  public clearTable(table: string): void {
    delete this.cache[table];
  }

  /**
   * Clear all cached data across all tables.
   */
  public clearAll(): void {
    this.cache = {};
  }
}
