import { getFnoName, ILiveMockConfig, IPagedResponse, LiveMockService, mapToNull, setLocalStoreItem } from '@aex/ngx-toolbox';
import { ConfigApi, OperatorApi, SettingsApi } from '@aex/shared/apis';
import {
	AuthType,
	COUNTRY_CODE,
	DEFAULT_CONFIG,
	ENTITY,
	ENVIRONMENT_CONFIG_TOKEN,
	IBackOfficeConfig,
	IEnvFile,
	IEnvironment,
	IOperator,
	IS_ISP_ADMIN,
	ParamMetaData,
	PROVIDER,
	SERVER_CONFIG,
	Utils,
} from '@aex/shared/common-lib';
import { AppConfigLoaderService, ConfigService } from '@aex/shared/root-services';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { merge } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PortalConfigService } from './portal-config.service';

@Injectable()
export class PortalAppConfigLoaderService extends AppConfigLoaderService {

	public get portalConfigService(): PortalConfigService {
		return this.configService as PortalConfigService;
	}

	constructor(
		http: HttpClient,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
		private readonly configService: ConfigService,
		private readonly liveMockService: LiveMockService,
	) {
		super(http, environment);
	}

	protected loadOverridableServerUrls(): void {
		SERVER_CONFIG.paymentServerUrl = this.environment.paymentServerUrl;
		SERVER_CONFIG.purchaseOrderServerUrl = this.environment.purchaseOrderServerUrl;
		SERVER_CONFIG.pricingServerUrl = this.environment.pricingServerUrl;
	}

	public override loadAppConfig(): Observable<void> {
		this.initLoadAppConfig();

		return this.http
			.get<IEnvFile>(ConfigApi.dynamicEnvironment, {
				params: new ParamMetaData({ authToken: AuthType.NONE }),
			})
			.pipe(
				switchMap((envConfig: IEnvFile) => {
					merge(this.environment, envConfig.environments[this.portalConfigService.getConfigName()], this.environment.override);

					this.portalConfigService.fnoName = getFnoName(this.environment, envConfig.operatorResolution);

					this.portalConfigService.operatorBehaviorSubject.next(this.portalConfigService.fnoName);
					return this.portalConfigService.getFnoConfig(this.portalConfigService.fnoName, this.portalConfigService.getConfigName());
				}),
				tap((config) => {
					// manipulate as needed - this was copied from client-interface
					this.portalConfigService.startUpData = {
						...config,
						...config[this.portalConfigService.getConfigName()],
						...this.environment.override,
					};
					merge(this.environment, this.portalConfigService.startUpData.environmentOverride);
					this.loadOverridableServerUrls();
					this.portalConfigService.configValues = this.portalConfigService.startUpData;

					this.loadServerUrls();
					this.liveMockService.config = this.getLiveMockServiceConfig();
				}),
				catchError(() => this.http.get<IBackOfficeConfig>(ConfigApi.config(DEFAULT_CONFIG, this.portalConfigService.getConfigName()))),
				mapToNull(),
			);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getOperatorSettings(params: any = {}): Observable<any> {
		return this.http.get(SettingsApi.settings(), {
			params: new ParamMetaData({ handleError: 'settings' }).set('operator_guid', this.portalConfigService.operatorId).setQuery(params.query).withAllCount(),
		});
	}

	public getFno(): Observable<IOperator> {
		return this.http
			.get<IPagedResponse<IOperator>>(OperatorApi.operator(), {
				params: new ParamMetaData({ handleError: 'operator' }).set('guid', this.portalConfigService.operatorId),
			})
			.pipe(map((operators: IPagedResponse<IOperator>) => operators?.items[0]));
	}

	public override getLiveMockServiceConfig(): ILiveMockConfig {
		return {
			enabled: this.environment.mocksEnabled,
			serverUrls: [
				this.environment.ispServerUrl,
				this.environment.fnoServerUrl,
				this.environment.securityServiceUrl,
				this.environment.paymentServerUrl,
				this.environment.purchaseOrderServerUrl,
				this.environment.pricingServerUrl,
				this.environment.searchServiceUrl,
			],
			portNumbers: [],
		};
	}

	protected override initLoadAppConfig(): void {
		const fnoName = this.portalConfigService.fnoName;
		this.storeParam(fnoName, PROVIDER, 'provider');
		this.storeParam(fnoName, ENTITY, 'entity');
		this.storeParam(fnoName, IS_ISP_ADMIN, 'is_isp_admin');
		this.storeParam(fnoName, COUNTRY_CODE, COUNTRY_CODE);
	}

	protected override loadServerUrls(): void {
		SERVER_CONFIG.securityServiceUrl = this.environment.securityServiceUrl;
		SERVER_CONFIG.serverUrl = this.portalConfigService.startUpData.isIsp ? this.environment.ispServerUrl: this.environment.fnoServerUrl;
		SERVER_CONFIG.ipServerUrl = this.environment.ipPoolServerUrl;
		SERVER_CONFIG.paymentServerUrl = this.environment.paymentServerUrl;
		SERVER_CONFIG.purchaseOrderServerUrl = this.environment.purchaseOrderServerUrl;
		SERVER_CONFIG.pricingServerUrl = this.environment.pricingServerUrl;
		SERVER_CONFIG.searchServerUrl = this.environment.searchServiceUrl;
		SERVER_CONFIG.radiusServerUrl = this.environment.radiusServiceUrl;
		SERVER_CONFIG.billingServiceUrl = this.environment.billingServiceUrl;
		SERVER_CONFIG.workOrderEventUrl = this.environment.workOrderEventUrl;
		SERVER_CONFIG.eventsUrl = this.environment.eventsUrl;
		SERVER_CONFIG.speedTestUrl = this.environment.speedTestUrl;
		SERVER_CONFIG.assetsServiceUrl = this.environment.assetsServerUrl;
		SERVER_CONFIG.kmlServerUrl = this.environment.kmlServerUrl;
		SERVER_CONFIG.notificationServiceUrl = this.environment.notificationServiceUrl;
		SERVER_CONFIG.portalUrl = this.portalConfigService.startUpData.fnoPortalBaseUrl;
		SERVER_CONFIG.sisenseReportUrl = this.environment.sisenseReportUrl;
	}

	private storeParam(fnoName: string, key: string, param: string): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const value: any = Utils.parameter(param);
		if (value)
			setLocalStoreItem(`${fnoName} - ${key}`, value, true);
	}
}
