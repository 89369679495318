import { ServiceApi } from '@aex/shared/apis';
import { IApiCommonFilters, IGlobalSearch, IGlobalSearchResponse, IGridColumn, IServicesGridData } from '@aex/shared/common-lib';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IColumnDto, ISearchPagedResponse, IServiceSearchParams } from 'libs/shared/common-lib/src/interfaces/search-service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	constructor(private readonly http: HttpClient) {
	}

	public getAllCustomers(body: IApiCommonFilters): Observable<HttpResponse<IServicesGridData>> {
		return this.http.post<IServicesGridData>(ServiceApi.searchServiceServicesSearch, body, { observe: 'response' });
	}

	public getAllCustomerColumns(): Observable<HttpResponse<IGridColumn[]>> {
		return this.http.get<IGridColumn[]>(ServiceApi.servicesColumns, { observe: 'response' });
	}

	public exportCustomerGridData(selected_grid_columns: IGridColumn[]): Observable<string> {
		return this.http.post(ServiceApi.servicesExportCsv(), { selected_grid_columns: selected_grid_columns }, { responseType: 'text' });
	}

	public globalSearch(body: IGlobalSearch) {
		return this.http.post<IGlobalSearchResponse>(ServiceApi.globalSearch, body, { observe: 'response' });

	}

	// Get all columns from search-service using the way it was done in fno-frontend
	public getServiceColumns(): Observable<HttpResponse<IColumnDto[]>> {	
		return this.http.get<IColumnDto[]>(ServiceApi.servicesColumns, { observe: 'response' });
	}

	// Get all services from search-service using the way it was done in fno-frontend
	public getServices(request: Partial<IServiceSearchParams>): Observable<HttpResponse<ISearchPagedResponse>> {
		return this.http.post<ISearchPagedResponse>(ServiceApi.searchServiceServicesSearch, request, { observe: 'response' });
	}

}
